import { Effect } from "../../Materials/effect";

let name = 'morphTargetsVertexGlobal';
let shader = `#ifdef MORPHTARGETS
#ifdef MORPHTARGETS_TEXTURE
float vertexID;
#endif
#endif`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var morphTargetsVertexGlobal = { name, shader };
