//import { Axis } from "@babylonjs/core/Maths/math.axis";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes";
import { Nullable } from "@babylonjs/core/types";
import { Animation } from "@babylonjs/core/Animations";
import { Editor } from "./Editor";

export enum EditorError {
    Ok, // 成功
    BeyondBorders = 1, // 越界
    Intersects = 2, // 交叉
}

export abstract class GameObject {

    protected _disposing: boolean;  // 释放中

    protected _disposable: boolean;
    protected _isEditor: boolean;
    protected _isAnimation: boolean;

    // protected _error: EditorError = EditorError.Ok;

    // public get Error(): EditorError {
    //     return this._error;
    // }

    // public set Error(value: EditorError) {
    //     this._error = value;

    //     //
    // }

    public abstract get Mesh(): Mesh;

    private _selected: boolean = false;
    public get Selected(): boolean {
        return this._selected;
        //return this.Mesh.showBoundingBox;
    }

    public set Selected(value: boolean) {
        //this.Mesh.showBoundingBox = value;
        if(value)
            this.AddHighlight();
        else
            this.RemoveHighlight();

        console.log("Selected-" + value);
        this._selected = value;
    }

    constructor(disposable: boolean = true, isEditor: boolean = true, isAnimation: boolean = true) {
        this._disposable = disposable;
        this._isEditor = isEditor;
        this._isAnimation = isAnimation;

        this._disposing = false;
    }

    public Rotate(axis: Vector3, forword: number = 1): boolean {
        //var mesh = 
        //console.log("GameObject Rotate");

        return false;
    }

    public Move(axis: Vector3, forword: number = 1): boolean {
        //var mesh = 
        //console.log("GameObject Move");

        return false;
    }

    public ForEach(callback: Nullable<Function> = null) {

    }

    public AddHighlight() {
        Editor.Instance.HighlightMesh(this.Mesh, true);
    }

    public RemoveHighlight() {
        Editor.Instance.HighlightMesh(this.Mesh);
    }

    protected doCreateAnimation(callback: Nullable<Function> = null) {
        // run animation
        let anim: Animation = new Animation(
            "popup",
            "scaling",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        let keys: Array<{ frame: number, value: Vector3 }> = new Array<{ frame: number, value: Vector3 }>();
        keys.push({
            frame: 0,
            value: new Vector3(0.6, 0.6, 0.6)
        });
        keys.push({
            frame: 10,
            value: new Vector3(1, 1, 1)
        });
        anim.setKeys(keys);
        this.Mesh.animations = [];
        this.Mesh.animations.push(anim);
        Editor.Scene.beginAnimation(this.Mesh, 0, 10, false, 1, () => {
            if (callback)
                callback();
        });
    }

    protected doDisposeAnimation(callback: Nullable<Function> = null) {
        // run animation
        let anim: Animation = new Animation(
            "popup",
            "scaling",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        let keys: Array<{ frame: number, value: Vector3 }> = new Array<{ frame: number, value: Vector3 }>();
        keys.push({
            frame: 0,
            value: new Vector3(1, 1, 1)
        });
        keys.push({
            frame: 10,
            value: new Vector3(0, 0, 0)
        });
        anim.setKeys(keys);
        this.Mesh.animations = [];
        this.Mesh.animations.push(anim);
        Editor.Scene.beginAnimation(this.Mesh, 0, 10, false, 1, () => {
            if (callback)
                callback();
        });
    }

    public Dispose(): void {
        this._disposing = true;
    }

    public Clear(): void {
        this._disposing = true;
    }

    public static PlayErrorAnimation(mesh:Mesh){
        var animatables = Editor.Scene.getAllAnimatablesByTarget(mesh);
        animatables.forEach(animatable => {
            animatable.stop("error");
        });
        //Editor.Scene.stopAllAnimations();
        mesh.scaling = new Vector3(1, 1, 1);

        // run animation
        let anim: Animation = new Animation(
            "error",
            "scaling",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CYCLE
        );

        let keys: Array<{ frame: number, value: Vector3 }> = new Array<{ frame: number, value: Vector3 }>();
        keys.push({
            frame: 0,
            value: new Vector3(0.9, 0.9, 0.9)
        });
        keys.push({
            frame: 10,
            value: new Vector3(1, 1, 1)
        });
        keys.push({
            frame: 20,
            value: new Vector3(0.9, 0.9, 0.9)
        });
        anim.setKeys(keys);
        mesh.animations = [];
        mesh.animations.push(anim);

        Editor.Scene.beginAnimation(mesh, 0, 20, true, 1, () => {
            
        }, );
    }

    public static StopErrorAnimation(mesh:Mesh){
        var animatables = Editor.Scene.getAllAnimatablesByTarget(mesh);
        animatables.forEach(animatable => {
            animatable.stop("error");
        });
        //Editor.Scene.stopAllAnimations();
        mesh.scaling = new Vector3(1, 1, 1);
    }
}