
/**
 * @hidden
 **/
export class StencilState {
    /** Passed to depthFunction or stencilFunction to specify depth or stencil tests will always pass. i.e. Pixels will be drawn in the order they are drawn */
    public static readonly ALWAYS = 519;
    /** Passed to stencilOperation to specify that stencil value must be kept */
    public static readonly KEEP = 7680;
    /** Passed to stencilOperation to specify that stencil value must be replaced */
    public static readonly REPLACE = 7681;

    protected _isStencilTestDirty = false;
    protected _isStencilMaskDirty = false;
    protected _isStencilFuncDirty = false;
    protected _isStencilOpDirty = false;

    protected _stencilTest: boolean;

    protected _stencilMask: number;

    protected _stencilFunc: number;
    protected _stencilFuncRef: number;
    protected _stencilFuncMask: number;

    protected _stencilOpStencilFail: number;
    protected _stencilOpDepthFail: number;
    protected _stencilOpStencilDepthPass: number;

    public get isDirty(): boolean {
        return this._isStencilTestDirty || this._isStencilMaskDirty || this._isStencilFuncDirty || this._isStencilOpDirty;
    }

    public get stencilFunc(): number {
        return this._stencilFunc;
    }

    public set stencilFunc(value: number) {
        if (this._stencilFunc === value) {
            return;
        }

        this._stencilFunc = value;
        this._isStencilFuncDirty = true;
    }

    public get stencilFuncRef(): number {
        return this._stencilFuncRef;
    }

    public set stencilFuncRef(value: number) {
        if (this._stencilFuncRef === value) {
            return;
        }

        this._stencilFuncRef = value;
        this._isStencilFuncDirty = true;
    }

    public get stencilFuncMask(): number {
        return this._stencilFuncMask;
    }

    public set stencilFuncMask(value: number) {
        if (this._stencilFuncMask === value) {
            return;
        }

        this._stencilFuncMask = value;
        this._isStencilFuncDirty = true;
    }

    public get stencilOpStencilFail(): number {
        return this._stencilOpStencilFail;
    }

    public set stencilOpStencilFail(value: number) {
        if (this._stencilOpStencilFail === value) {
            return;
        }

        this._stencilOpStencilFail = value;
        this._isStencilOpDirty = true;
    }

    public get stencilOpDepthFail(): number {
        return this._stencilOpDepthFail;
    }

    public set stencilOpDepthFail(value: number) {
        if (this._stencilOpDepthFail === value) {
            return;
        }

        this._stencilOpDepthFail = value;
        this._isStencilOpDirty = true;
    }

    public get stencilOpStencilDepthPass(): number {
        return this._stencilOpStencilDepthPass;
    }

    public set stencilOpStencilDepthPass(value: number) {
        if (this._stencilOpStencilDepthPass === value) {
            return;
        }

        this._stencilOpStencilDepthPass = value;
        this._isStencilOpDirty = true;
    }

    public get stencilMask(): number {
        return this._stencilMask;
    }

    public set stencilMask(value: number) {
        if (this._stencilMask === value) {
            return;
        }

        this._stencilMask = value;
        this._isStencilMaskDirty = true;
    }

    public get stencilTest(): boolean {
        return this._stencilTest;
    }

    public set stencilTest(value: boolean) {
        if (this._stencilTest === value) {
            return;
        }

        this._stencilTest = value;
        this._isStencilTestDirty = true;
    }

    public constructor(reset = true) {
        if (reset) {
            this.reset();
        }
    }

    public reset() {
        this._stencilTest = false;
        this._stencilMask = 0xFF;

        this._stencilFunc = StencilState.ALWAYS;
        this._stencilFuncRef = 1;
        this._stencilFuncMask = 0xFF;

        this._stencilOpStencilFail = StencilState.KEEP;
        this._stencilOpDepthFail = StencilState.KEEP;
        this._stencilOpStencilDepthPass = StencilState.REPLACE;

        this._isStencilTestDirty = true;
        this._isStencilMaskDirty = true;
        this._isStencilFuncDirty = true;
        this._isStencilOpDirty = true;
    }

    public apply(gl: WebGLRenderingContext) {
        if (!this.isDirty) {
            return;
        }

        // Stencil test
        if (this._isStencilTestDirty) {
            if (this.stencilTest) {
                gl.enable(gl.STENCIL_TEST);
            } else {
                gl.disable(gl.STENCIL_TEST);
            }
            this._isStencilTestDirty = false;
        }

        // Stencil mask
        if (this._isStencilMaskDirty) {
            gl.stencilMask(this.stencilMask);
            this._isStencilMaskDirty = false;
        }

        // Stencil func
        if (this._isStencilFuncDirty) {
            gl.stencilFunc(this.stencilFunc, this.stencilFuncRef, this.stencilFuncMask);
            this._isStencilFuncDirty = false;
        }

        // Stencil op
        if (this._isStencilOpDirty) {
            gl.stencilOp(this.stencilOpStencilFail, this.stencilOpDepthFail, this.stencilOpStencilDepthPass);
            this._isStencilOpDirty = false;
        }
    }
}
