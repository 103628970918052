import { VertexData } from '@babylonjs/core/Meshes'
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
import { Editor } from './Editor';
//import { Data } from './Data';

//const castle = JSON.stringify(require("../../assets/vox/castle.json"));
//const chr_sword = JSON.stringify(require("../../assets/vox/chr_sword.json"));
//const _8x8x8 = JSON.stringify(require("../../assets/vox/8x8x8.json"));

export class Meshes {

    private static _instance: Meshes;
    public static get Instance(): Meshes {
        return Meshes._instance;
    }

    public ListJson: Array<string> = [];
    public ListQumeta: Array<string> = [];

    private constructor() {

    }

    static Initialize(): void {
        var meshes = new Meshes();
        Meshes._instance = meshes;

        // // 方块4
        // meshes.ListJson.push('{"Blocks":[{"x":0,"y":0,"z":0,"w":1},{"x":0,"y":0,"z":1,"w":2},{"x":1,"y":0,"z":1,"w":3},{"x":1,"y":0,"z":0,"w":4}]}');
        // // 长条8
        // meshes.ListJson.push('{"Blocks":[{"x":0,"y":0,"z":0,"w":2},{"x":1,"y":0,"z":0,"w":2},{"x":2,"y":0,"z":0,"w":2},{"x":3,"y":0,"z":0,"w":2},{"x":4,"y":0,"z":0,"w":2},{"x":5,"y":0,"z":0,"w":2},{"x":6,"y":0,"z":0,"w":2},{"x":7,"y":0,"z":0,"w":2}]}');
        // // 叠加4*3
        // meshes.ListJson.push('{"Blocks":[{"x":0,"y":0,"z":0,"w":1},{"x":0,"y":0,"z":1,"w":2},{"x":1,"y":0,"z":1,"w":3},{"x":1,"y":0,"z":0,"w":4},{"x":1,"y":1,"z":1,"w":1},{"x":1,"y":1,"z":2,"w":2},{"x":2,"y":1,"z":2,"w":3},{"x":2,"y":1,"z":1,"w":4},{"x":2,"y":2,"z":2,"w":1},{"x":2,"y":2,"z":3,"w":2},{"x":3,"y":2,"z":3,"w":3},{"x":3,"y":2,"z":2,"w":4}]}');

        // meshes.ListJson.push('{"Blocks":[{"x":0,"y":0,"z":0,"w":0},{"x":0,"y":0,"z":1,"w":0},{"x":1,"y":0,"z":1,"w":0},{"x":1,"y":0,"z":0,"w":0}]}');
        // // 
        // meshes.ListJson.push('{"Blocks":[{"x":0,"y":0,"z":0,"w":0},{"x":0,"y":0,"z":1,"w":0},{"x":1,"y":0,"z":1,"w":0},{"x":1,"y":0,"z":0,"w":0}]}');
        // // 6 大块
        // meshes.ListJson.push(_8x8x8);
        // // 7 角色
        // meshes.ListJson.push(chr_sword);
        // // 8城堡
        // meshes.ListJson.push(castle);


        // qumeta( id --> string)
    }

    // 模拟服务器取得model数据
    public static GetData(id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            //LiveVoxelEditor
            //window.location.pathname

            var assetsManager = new AssetsManager(Editor.Scene);
            var textTask = assetsManager.addTextFileTask("data task", `./cube/${id}.qumeta`);


            textTask.onSuccess = function (task) {
                resolve(task.text);
            }

            textTask.onError = function (task, message, exception) {
                if (message)
                    console.error(message);
            }

            // var binaryTask = assetsManager.addBinaryFileTask("binary task", "grass.jpg");
            // binaryTask.onSuccess = function (task) {
            //     // Do something with task.data
            // }

            assetsManager.onFinish = function (tasks) {
                // ok
                //TextFileAssetTask
            };

            assetsManager.load();
        });
    }
}
