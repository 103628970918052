import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import { Material } from "@babylonjs/core/Materials/material";
import { Quaternion, Vector3, Matrix } from "@babylonjs/core/Maths/math.vector";
import { BoxBuilder } from "@babylonjs/core/Meshes/Builders/boxBuilder";
import { Mesh } from "@babylonjs/core/Meshes";
//import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Editor } from "./Editor";
import { Materials } from "./Materials";
//import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Nullable } from "@babylonjs/core/types";
import { BoundingBoxRenderer } from "@babylonjs/core/Rendering/boundingBoxRenderer"; // showBoundingBox
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
//import { Animation } from "@babylonjs/core/Animations";
import { ModelData } from "./ModelData";
import { GameObject } from "./GameObject";
import { Tools } from "@babylonjs/core/Misc/tools";
import { Axis, Space } from "@babylonjs/core/Maths/math";
import { MessageLineComponent } from "@babylonjs/inspector/sharedUiComponents/lines/messageLineComponent";

export class GameModel extends GameObject {
    private static Id: number = 0;  // 作为自增id索引
    public static Instances: Array<GameModel> = new Array<GameModel>();

    public static LiveInstances(): Array<GameModel> {
        return GameModel.Instances.filter((item) => { return (item._disposing == false); });
    }

    private _id!: number; // 预览模式-1
    public get Id(): number {
        return this._id;
    }

    private _pos: Vector3;  // 位置
    public get Pos(): Vector3 {
        return this._pos;
    }

    private _rotationQuaternion: Quaternion;   // 旋转
    public RotationQuaternion(): Quaternion {
        return this._rotationQuaternion;
    }

    private _data: ModelData;
    public get Data(): ModelData {
        return this._data;
    }

    //private _root: TransformNode;
    private _root!: Mesh;
    private _mergeMesh!: Mesh;

    // public get Selected(): boolean {
    //     return this._root.showBoundingBox;
    // }

    // public set Selected(value: boolean) {
    //     this._root.showBoundingBox = value;
    // }

    public get Mesh(): Mesh {
        return this._root;
    }

    public get MergedMesh(): Mesh {
        return this._mergeMesh;
    }

    constructor(pos: Vector3, data: ModelData,
        rotation: Vector3 = Vector3.ZeroReadOnly,
        disposable: boolean = true,
        isEditor: boolean = true,
        isAnimation: boolean = true) {
        super(disposable, isEditor, isAnimation);

        this._pos = pos.clone();
        this._data = data;
        this._rotationQuaternion = Quaternion.FromEulerVector(rotation);

        this._disposable = disposable;

        //rotation.y = 3;
        this.Initialize();
    }

    public static PreName = "SubGameObject_";

    public static MathName(name: string): boolean {
        if (name.startsWith(GameModel.PreName))
            return true;

        return false;
    }

    private Initialize(): void {

        this._id = GameModel.Id;
        GameModel.Id = GameModel.Id + 1;
        GameModel.Instances[this._id] = this;

        //this._root = new TransformNode(group + ":Root", Editor.Scene);
        this._root = new Mesh(this._data.ID + ":Root", Editor.Scene);
        this._root.position.copyFrom(this._pos);
        //this._root.rotation = new Vector3(this._rotation.x * Math.PI / 2, this._rotation.y * Math.PI / 2, this._rotation.z * Math.PI / 2);
        this._root.rotationQuaternion = this._rotationQuaternion;
        this._root.computeWorldMatrix();

        // Mesh.MergeMeshes() how?
        // from modeldata
        var arrayOfMeshes: Array<Mesh> = [];

        this._data.Blocks.forEach((item) => {
            var mesh = BoxBuilder.CreateBox(GameModel.PreName + this._id, {}, Editor.Scene);
            //var mesh = MeshBuilder.CreateBox(GameModel.PreName + this._id, {}, Editor.Scene);
            mesh.setParent(this._root);
            mesh.position = new Vector3(item.x, item.y, item.z);
            mesh.metadata = item.w; // block color

            let mat: Material = Materials.List[item.w];
            mesh.material = mat;

            mesh.computeWorldMatrix();

            arrayOfMeshes.push(mesh);
        });

        //arrayOfMeshes = this._root.getChildMeshes() as Mesh[];
        /* showBoundingBox
        let childMeshes = this._root.getChildMeshes() as Mesh[];
        if (childMeshes.length == 0)
            return;

        let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
        let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

        for (let i = 0; i < childMeshes.length; i++) {
            let meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
            let meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

            min = Vector3.Minimize(min, meshMin);
            max = Vector3.Maximize(max, meshMax);
        }

        //console.log("info:" + min + ":" + max);

        this._root.setBoundingInfo(new BoundingInfo(min.subtractInPlace(this._root.position), max.subtractInPlace(this._root.position)));
        //this._root.showBoundingBox = true
        */

        this._mergeMesh = new Mesh(this._data.ID + ":Root:Merge", Editor.Scene);
        this._mergeMesh.metadata = 0; // merge
        //this._mergeMesh.isPickable = false;

        this._mergeMesh.setParent(this._root);
        //parameters - arrayOfMeshes, disposeSource, allow32BitsIndices, meshSubclass, subdivideWithSubMeshes, multiMultiMaterial
        var disposeSource = false;
        var newMesh = Mesh.MergeMeshes(arrayOfMeshes, disposeSource, true, this._mergeMesh, false, true);
        if (newMesh) {
            newMesh.name = GameModel.PreName + this._id;
            newMesh.metadata = 0; // merge
            //newMesh.isPickable = false;
        }
        this._mergeMesh.isVisible = false;
        
        if (this._isAnimation) {
            this.doCreateAnimation(() => {
                Editor.Instance.OnGameObjectCreated(this);
            });
        } else {

        }
    }

    public Dispose(): void {
        super.Dispose();

        if (this._disposable) {
            this._root.showBoundingBox = false;

            this.doDisposeAnimation(() => {
                let childMeshes = this._root.getChildMeshes();
                if (childMeshes.length > 0) {
                    for (let i = 0; i < childMeshes.length; i++) {
                        childMeshes[i].dispose();
                    }
                }
                this._root.dispose();
                delete GameModel.Instances[this.Id];
            });
        }
    }

    public Clear(): void {
        super.Clear();

        this._root.showBoundingBox = false;

        let childMeshes = this._root.getChildMeshes();
        if (childMeshes.length > 0) {
            for (let i = 0; i < childMeshes.length; i++) {
                childMeshes[i].dispose();
            }
        }
        this._root.dispose();
        delete GameModel.Instances[this.Id];
    }

    public Rotate(axis: Vector3, forword: number = 1): boolean {
        // var flipBack: number = 0;
        // var twirlClockwise: number = 0;
        // var tiltRight: number = 0;

        // TODO旋转进入地面下
        var angle = Tools.ToRadians(90 * forword);

        // if (axis === Axis.X) {
        //     flipBack = angle;
        // } else if (axis === Axis.Y) {
        //     twirlClockwise = angle;
        // } else if (axis === Axis.Z) {
        //     tiltRight = angle;
        // }

        this._root.rotateAround(this._root.position, axis, angle);
        this._rotationQuaternion = this._root.rotationQuaternion!;

        return true;
    }

    private getMinY(): number {
        var minY = Number.MAX_VALUE;
        let childMeshes = this._root.getChildMeshes();
        if (childMeshes.length > 0) {
            for (let i = 0; i < childMeshes.length; i++) {
                var mesh = childMeshes[i];
                if(mesh.metadata == 0) // skip
                    continue;
                // TODO cache toint
                // mesh.absolutePosition
                let pos = mesh.getAbsolutePosition();

                minY = Math.min(minY, Math.round(pos.y));
            }
        }

        return minY;
    }

    private isUnderGround(amountUp: number): boolean {
        // 进入地下判断
        if (amountUp > 0)
            return false;

        if ((this.getMinY() + amountUp) < 0)
            return true;

        return false;
    }

    public Move(axis: Vector3, forword: number = 1): boolean {
        if (axis === Axis.Y) {
            if (this.isUnderGround(forword))
                return false;
        }

        this._root.translate(axis, forword, Space.WORLD);
        // Bug ? must cal matrix just now
        this._root.computeWorldMatrix();
        this._pos.copyFrom(this._root.position);
        //console.log(this._pos);

        return true;
    }

    public ForEach(callback: Nullable<Function> = null) {
        let childMeshes = this._root.getChildMeshes();
        if (childMeshes.length > 0) {
            for (let i = 0; i < childMeshes.length; i++) {
                var mesh = childMeshes[i];
                if(mesh.metadata == 0) // skip
                    continue;

                // TODO cache toint
                // mesh.absolutePosition
                let pos = mesh.getAbsolutePosition();

                if (callback)
                    callback(mesh, Math.round(pos.x), Math.round(pos.y), Math.round(pos.z), +mesh.metadata);
            }
        }

    }

    public AddHighlight() {
        //this._mergeMesh.isVisible = true;
        let childMeshes = this._root.getChildMeshes();
        if (childMeshes.length > 0) {
            for (let i = 0; i < childMeshes.length; i++) {
                var mesh = childMeshes[i];
                mesh.isVisible = (mesh.metadata == 0) ? true : false;
            }
        }

        Editor.Instance.HighlightMesh(this._mergeMesh, true);
    }

    public RemoveHighlight() {
        Editor.Instance.HighlightMesh(this._mergeMesh);
        //this._mergeMesh.isVisible = false;
        let childMeshes = this._root.getChildMeshes();
        if (childMeshes.length > 0) {
            for (let i = 0; i < childMeshes.length; i++) {
                var mesh = childMeshes[i];
                mesh.isVisible = (mesh.metadata == 0) ? false : true;
            }
        }
    }

    public static FindByMesh(mesh: AbstractMesh): Nullable<GameModel> {
        //"SubGameObject_99_groupname"
        //console.log(mesh.name.split("_")[1]);
        let idString: string = mesh.name.slice(GameModel.PreName.length);
        let id: number = parseInt(idString, 10);
        if (!isNaN(id)) {
            return GameModel.Instances[id];
        }
        return null;
    }

    // 位置模型数据id匹配就找到
    public static FindByPositionData(pos: Vector3, data: ModelData): Nullable<GameModel> {
        var array = GameModel.Instances.filter((item) => { 
            return (item.Pos.equals(pos) && (item.Data.ID == data.ID));
         });

        if (array.length > 0)
            return array[0];

        return null;
    }
}