import { Vector3, Vector4 } from "@babylonjs/core/Maths/math.vector";
import { EditorData } from "./EditorData";

export class ModelData {
    public ID: string = "";
    public Blocks: Array<Vector4> = [];

    constructor(id: string, blocks: Array<Vector4> = []) {
        this.ID = id;
        this.Blocks = blocks;
    }

    public Deserialize(data: string): { width: number, depth: number, height: number } {
        let strList = data.split(",")

        let width = parseInt(strList[0]) // width == depth 51
        let depth = parseInt(strList[1])
        let height = parseInt(strList[2])

        if (width != depth || height <= 0)
            return { width, depth, height };

        let content = strList[3];
        EditorData.DeserializeData(this.Blocks, content, width, depth, height);

        return { width, depth, height };
    }
}