//

export enum ExecuteEnum {
    AddGameBlock,
    AddGameModel,
    Delete,
    Move,
    Rotate,
    AutoCenter,
    SkyColor,
    GroundColor
}

export enum ModelEnum {
    Block,
    Model
}

export interface ExecuteData {
    type:ExecuteEnum
    // ...
}