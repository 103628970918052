import { AbstractMesh, Mesh, VertexData, BoxBuilder } from '@babylonjs/core/Meshes'
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Material } from "@babylonjs/core/Materials/Material";
//import { Color3 } from "@babylonjs/core/Maths/math.color";
//import { Animation } from "@babylonjs/core/Animations";

import { Nullable } from "@babylonjs/core/types"

import { GameBlockData } from './GameBlockData';
import { Editor } from './Editor';
import { Materials } from './Materials';
//import { Meshes } from './Meshes';
import { GameObject } from './GameObject';
import { Axis, Space } from '@babylonjs/core/Maths/math.axis';

export enum BlockColor {
    None = 0, // 没有选择块时，为选择模式，有颜色块时为创建模式
    Green,
    Black,
    Blue,
    Orange,
    Purple,
    Red,
    White,
    Yellow,
    Error
}

export class GameBlock extends GameObject {
    private static Id: number = 0;  // 作为自增id索引
    public static Instances: Array<GameBlock> = new Array<GameBlock>();

    public static LiveInstances(): Array<GameBlock> {
        return GameBlock.Instances.filter((item) => { return (item._disposing == false); });
    }

    public static BlockCode: Array<string> = [
        '_', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'
    ];

    private static BlockDecoder: any = null;

    public static BlockDecode(val: string): number {
        if (GameBlock.BlockDecoder === null) {
            GameBlock.BlockDecoder = {};

            for (let i = 0; i < GameBlock.BlockCode.length; i++) {
                GameBlock.BlockDecoder[GameBlock.BlockCode[i]] = i;
            }
        }

        return GameBlock.BlockDecoder[val];
    }

    private _id!: number; // 预览模式-1
    public get Id(): number {
        return this._id;
    }

    private _pos: Vector3;  // 位置
    public get Pos(): Vector3 {
        return this._pos;
    }

    private _color: number;
    public get Color(): number {
        return this._color;
    }

    private _mesh!: Mesh;
    //private _disposable: boolean;

    // public get Selected(): boolean {
    //     return this._mesh.showBoundingBox;
    // }

    // public set Selected(value: boolean) {
    //     this._mesh.showBoundingBox = value;
    // }

    public get Mesh(): Mesh {
        return this._mesh;
    }

    constructor(
        pos: Vector3,
        color: number,
        disposable: boolean = true,
        isEditor: boolean = true,
        isAnimation: boolean = true,
    ) {
        super(disposable, isEditor, isAnimation);

        this._pos = pos;
        this._color = color;
        //this._disposable = disposable;

        this.Initialize(disposable);
    }

    public static PreName = "GameBlock_";

    public static MathName(name: string): boolean {
        if (name.startsWith(GameBlock.PreName))
            return true;

        return false;
    }

    public static GameObjectFromData(data: GameBlockData): GameBlock {
        return new GameBlock(new Vector3(data.posX, data.posY, data.posZ), data.color);
    }

    private Initialize(disposable: boolean): void {
        if (this._isEditor) {
            this._id = GameBlock.Id;
            GameBlock.Id = GameBlock.Id + 1;
            GameBlock.Instances[this._id] = this;
        } else {
            this._id = -1;
        }

        this._mesh = BoxBuilder.CreateBox(GameBlock.PreName + this._id, {}, Editor.Scene);

        //var instance = this._mesh.createInstance("");
        //instance.parent

        this._mesh.parent = Editor.Root;// 
        this._mesh.position.copyFrom(this._pos);
        this._mesh.metadata = this._color;

        let mat: Material = Materials.List[this._color];
        this._mesh.material = mat;

        if (this._isAnimation) {
            this.doCreateAnimation(() => {
                Editor.Instance.OnGameObjectCreated(this);
            });
        }
    }

    public Dispose(): void {
        super.Dispose();

        if (this._disposable) {
            this._mesh.showBoundingBox = false;

            this.doDisposeAnimation(() => {
                this._mesh.dispose();
                delete GameBlock.Instances[this.Id];
                //const index = GameBlock.Instances.indexOf(this.Id, 0);
                //if (index > -1) {
                //    GameBlock.Instances.splice(index, 1);
                //}
            });
        }
    }

    public Clear(): void {
        super.Clear();

        this._mesh.showBoundingBox = false;

        this._mesh.dispose();
        delete GameBlock.Instances[this.Id];
    }

    public Rotate(axis: Vector3, forword: number = 1): boolean {
        //console.log("GameModel Rotate:" + value);

        return false;
    }

    private isUnderGround(amountUp: number): boolean {
        // 进入地下判断
        if (amountUp > 0)
            return false;

        if ((this._pos.y + amountUp) < 0)
            return true;

        return false;
    }

    public Move(axis: Vector3, forword: number = 1): boolean {

        if (axis === Axis.Y) {
            if (this.isUnderGround(forword))
                return false;
        }

        this._mesh.translate(axis, forword, Space.WORLD);
        this._pos.copyFrom(this._mesh.position);

        return true;
    }

    public ForEach(callback: Nullable<Function> = null) {
        if (callback)
            callback(this._mesh, Math.round(this.Pos.x), Math.round(this.Pos.y), Math.round(this.Pos.z), this.Color);
    }

    public AddHighlight() {
        Editor.Instance.HighlightMesh(this.Mesh, true);
    }

    public RemoveHighlight() {
        Editor.Instance.HighlightMesh(this.Mesh);
    }

    public static FindByMesh(mesh: AbstractMesh): Nullable<GameBlock> {
        //"GameObject_"
        let idString: string = mesh.name.slice(GameBlock.PreName.length);
        let id: number = parseInt(idString, 10);
        //console.log(id);
        if (!isNaN(id)) {
            return GameBlock.Instances[id];
        }
        return null;
    }

    // 位置颜色匹配就找到
    public static FindByPositionData(pos: Vector3, color: number): Nullable<GameBlock> {
        var array = GameBlock.Instances.filter((item) => { return (item.Pos.equals(pos) && (item.Color == color)); });
        if (array.length > 0)
            return array[0];

        return null;
    }
}
