import { Vector3, Vector4, Quaternion } from "@babylonjs/core/Maths/math.vector";
import { GameBlock } from "./GameBlock";

//
export class RefModelData {
    public ID: string = "";
    public Position: Vector3 = Vector3.Zero();
    public Rotation: Vector3 = Vector3.Zero();

    constructor(id: string, position: Vector3, rotation: Vector3) {
        this.ID = id;
        this.Position = position;
        this.Rotation = rotation;
    }
}

// 游戏内格式
export class EditorData {
    public ID: string = "";
    public Range: number = 0;
    public Blocks: Array<Vector4> = [];
    public Refs: Array<RefModelData> = [];

    public constructor(id: string, range: number = 0, blocks: Array<Vector4> = [], refs: Array<RefModelData> = []) {
        this.ID = id;
        this.Range = range;
        this.Blocks = blocks;
        this.Refs = refs;
    }

    private convertRotate(degree: number): number {
        // 0 90 -180(180) -90
        switch (degree) {
            case 0:
                return 0;
            case 90:
                return 1;
            case 180:
            case -180:
                return 2;
            case -90:
                return 3;
            default:
                console.error(degree);
                break;
        }

        return 0;
    }

    private static convertPos(index: number, width: number, depth: number, height: number): any {
        // 51 --> 25
        var rangePlatHalf = Math.round((width - 1) / 2);

        return {
            x: Math.floor(index / depth) % width - rangePlatHalf,
            y: Math.floor(index / (width * depth)),
            z: index % depth - rangePlatHalf,
        }
    }

    // 查找边界(x-z平面最大值，级y轴最大值)
    public static FindRange(blocks: Array<Vector4>) {
        var maxY = 0;
        var maxPlatRangeHalf = 0;

        blocks.forEach(item => {
            maxY = Math.max(maxY, Math.abs(item.y));

            maxPlatRangeHalf = Math.max(maxPlatRangeHalf, Math.abs(item.x));
            maxPlatRangeHalf = Math.max(maxPlatRangeHalf, Math.abs(item.z));
        });

        // 以0为圆点
        var maxPlatRange = 2 * maxPlatRangeHalf + 1;
        maxY = maxY + 1;

        return { platRange: maxPlatRange, platHeight: maxY };

    }

    // width == depth 宽高默认相同，并且是奇数
    public static ConvertToCubeIndex(x: number, y: number, z: number, rangePlat: number): number {
        // 0,0,0
        // Editor.MaxRange 251 (-126,126)
        // range 15 (-7,7)
        // -7 --> 0 + 7
        // 0 --> 7
        // 7 --> 14
        // 0 --> 0
        // 坐标正值化
        var rangePlatHalf = Math.round((rangePlat - 1) / 2);

        var _x = x + rangePlatHalf;
        var _z = z + rangePlatHalf;

        return y * rangePlat * rangePlat + _x * rangePlat + _z;
    }

    // 最小化坐标(即range自动算出)
    public static SerializeData(blocks: Array<Vector4>, width?: number, depth?: number, height?: number): string {
        // 如果没有传入时，计算最小区域
        if (!width || !depth || !height) {
            var { platRange, platHeight } = EditorData.FindRange(blocks);

            width = platRange;
            depth = platRange;
            height = platHeight;
        }

        // 生成线性数组
        var lineBlocks: Array<number> = [];

        var total = width * depth * height;
        for (var i = 0; i < total; i++)
            lineBlocks[i] = 0;

        blocks.forEach((item) => {
            var index = EditorData.ConvertToCubeIndex(item.x, item.y, item.z, width!);
            lineBlocks[index] = item.w;
        });

        var str = "";

        // 连续相同的block合并
        for (var i = 0; i < total; i++) {
            let color = lineBlocks[i];
            var count = 0;
            while (count + 1 && i + 1 < lineBlocks.length && lineBlocks[i] == lineBlocks[i + 1]) {
                i++;
                count++;
            }

            // 0->_
            // 1->A
            str += GameBlock.BlockCode[color];
            if (count != 0) str += count;
        }

        str = width + "," + depth + "," + height + "," + str
        //console.log(str);
        return str;
    }

    public static DeserializeData(blocks: Array<Vector4>, content: string, width: number, depth: number, height: number) {
        let total_setted = 0;

        for (let i = 0; i < content.length; i++) {
            let c = content[i];
            let value = GameBlock.BlockDecode(c);
            let count = 0;
            for (let k = 1; k + i < content.length;) {
                let next_c = content[i + k]
                if (next_c < '0' || next_c > '9') break;
                count = count * 10 + parseInt(next_c);
                i++;
            }
            for (let k = 0; k <= count; k++) {
                //this._values[total_setted + k] = value;
                var { x, y, z } = EditorData.convertPos(total_setted + k, width, depth, height);
                //console.log(x + "," + y + "," + z + "," + value)

                // 有格子时
                if (value != 0) {
                    blocks.push(new Vector4(x, y, z, value))
                }
            }
            total_setted += count + 1;
        }
    }

    public Serialize(platRange: number, platHeight: number): string {
        // 格子信息
        var str = EditorData.SerializeData(this.Blocks, platRange, platRange, platHeight);

        var refStr = "";

        // 引用模型
        this.Refs.forEach((ref) => {
            //ref.ID
            //ref.Position
            //ref.Rotation
            refStr += ref.ID + ":";
            refStr += EditorData.ConvertToCubeIndex(ref.Position.x, ref.Position.y, ref.Position.z, platRange) + ":";
            //refStr += ref.Position.x + ":";
            //refStr += ref.Position.y + ":";
            //refStr += ref.Position.z + ":";
            refStr += this.convertRotate(ref.Rotation.x) + ":";
            refStr += this.convertRotate(ref.Rotation.y) + ":";
            refStr += this.convertRotate(ref.Rotation.z) + ";"; // ending
        });

        if (refStr.length > 0)
            str += ("," + refStr);

        return str;
    }

    public Deserialize(data: string): boolean {
        let strList = data.split(",")

        let width = parseInt(strList[0]) // width == depth 51
        let depth = parseInt(strList[1])
        let height = parseInt(strList[2])

        if (width != depth || height <= 0)
            return false;

        // width 51
        if (width > this.Range) {
            console.warn(width + "" + this.Range);
            //return false;
        }

        // 不解析区间
        this.Range = width;

        let content = strList[3];
        EditorData.DeserializeData(this.Blocks, content, width, depth, height);

        // refs
        if (strList.length > 4) {
            let refsStr = strList[4];

            strList = refsStr.split(";")
            for (var i = 0; i < strList.length; i++) {
                var strs = strList[i].split(":");
                if (strs.length < 5)
                    continue;

                //var id = strs[0];
                //var pos = strs[1];

                var index = +strs[1];
                var { x, y, z } = EditorData.convertPos(index, width, depth, height);

                var ref = new RefModelData(strs[0],
                    new Vector3(x, y, z),  // Position
                    new Vector3(+strs[2], +strs[3], +strs[4])); // Rotation
                this.Refs.push(ref);
            }
        }

        return true;
    }

    private static Buffer: ArrayBuffer = new ArrayBuffer(1024 * 4);

    // 导出链上保存的数据
    public SerializeToBuffer(platRange: number, platHeight: number)
        : { coordinates: ArrayBuffer, cites: Array<ArrayBuffer> } {
        var bufView = new Uint8Array(EditorData.Buffer);

        var width = platRange;
        var depth = platRange;
        var height = platHeight;

        var total = width * depth * height;

        //this.Blocks
        var index = 0;

        bufView[index++] = width;
        bufView[index++] = depth;
        bufView[index++] = platHeight;

        // 生成线性数组
        var lineBlocks: Array<number> = [];

        for (var i = 0; i < total; i++)
            lineBlocks[i] = 0;

        this.Blocks.forEach((item) => {
            var index = EditorData.ConvertToCubeIndex(item.x, item.y, item.z, width!);
            lineBlocks[index] = item.w;
        });

        // 1个半字节最大存储数
        var maxNum = (0xf << 8) + 0xff; // 4095

        // 连续相同的block合并
        for (var i = 0; i < total; i++) {
            let color = lineBlocks[i];
            var count = 1;
            while (count + 1 && i + 1 < lineBlocks.length && lineBlocks[i] == lineBlocks[i + 1]) {
                i++;
                count++;
            }

            // F FFF(颜色+块数)
            //color count
            if (count == 1) {
                bufView[index++] = (color << 4);
                bufView[index++] = count;
            } else {
                while (count > 0) {
                    var numEvery = count;
                    if (count > maxNum)
                        numEvery = maxNum;

                    bufView[index++] = ((color << 4) + (numEvery >> 8));
                    bufView[index++] = (numEvery & 0xff);

                    count -= numEvery;
                }
            }
        }

        var bufCoordinates = EditorData.Buffer.slice(0, index);
        var bufCites: Array<ArrayBuffer> = [];

        //this.Refs;
        for (var i = 0; i < this.Refs.length; i++) {
            var ref = this.Refs[i];
            var id = +ref.ID;

            // 重新计数
            index = 0;
            // 4byte
            bufView[index++] = id >> 24;
            bufView[index++] = (id & 0xffffff) >> 16;
            bufView[index++] = (id & 0xffff) >> 8;
            bufView[index++] = (id & 0xff);

            //var indexRef = EditorData.ConvertToCubeIndex(ref.Position.x, ref.Position.y, ref.Position.z, width!);
            var rangePlatHalf = Math.round((platRange - 1) / 2);

            // position(x, y, z)
            bufView[index++] = ref.Position.x + rangePlatHalf;
            bufView[index++] = ref.Position.y;
            bufView[index++] = ref.Position.z + rangePlatHalf;

            // 旋转点(暂时原点旋转不用)
            bufView[index++] = 0;
            bufView[index++] = 0;
            bufView[index++] = 0;

            // ref.Rotation
            bufView[index++] = this.convertRotate(ref.Rotation.x);
            bufView[index++] = this.convertRotate(ref.Rotation.y);
            bufView[index++] = this.convertRotate(ref.Rotation.z);

            bufCites.push(EditorData.Buffer.slice(0, index));
        }

        return { coordinates: bufCoordinates, cites: bufCites };
    }
}

// 序列化数据
// export class SerializeData {
//     public constructor(public id: string, public buffer: ArrayBuffer, public data: string) {

//     }
// }

export class PointerDragEvent {
    public constructor(public delta: Vector3,
        public dragPlanePoint: Vector3,
        public dragPlaneNormal: Vector3,
        public dragDistance: number,
        public pointerId: number) {

    }
}