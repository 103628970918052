import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Material } from "@babylonjs/core/Materials/Material";
import { Color3 } from "@babylonjs/core/Maths/math.color";

import { Editor } from './Editor';
import { BlockColor } from './GameBlock';

import greenTextureUrl from "../../assets/leblock/green.png";
import blackTextureUrl from "../../assets/leblock/black.png";
import blueTextureUrl from "../../assets/leblock/blue.png";
import orangeTextureUrl from "../../assets/leblock/orange.png";
import purpleTextureUrl from "../../assets/leblock/purple.png";
import redTextureUrl from "../../assets/leblock/red.png";
import whiteTextureUrl from "../../assets/leblock/white.png";
import yellowTextureUrl from "../../assets/leblock/yellow.png";

import errorTextureUrl from "../../assets/leblock/error.jpg";

import { Texture } from "@babylonjs/core/Materials/Textures/texture";

export class Materials {
    public static List: any = new Array<Material>();
    public static ListEditor: any = new Array<Material>();

    //
    static Initialize(): void {
        var useColor = false;
        if (useColor) {
            Materials.AddColor(BlockColor.Green, "#84CCA6");
            Materials.AddColor(BlockColor.Black, "#3F3F3F");
            Materials.AddColor(BlockColor.Blue, "#5190B3");
            Materials.AddColor(BlockColor.Orange, "#F7844D");
            Materials.AddColor(BlockColor.Purple, "#7257A9");
            Materials.AddColor(BlockColor.Red, "#CF4248");
            Materials.AddColor(BlockColor.White, "#F0F0F0");
            Materials.AddColor(BlockColor.Yellow, "#FDC767");

            //Materials.AddColor(BlockColor.Error, "#FF0000");
        } else {
            Materials.AddBlockTexture(BlockColor.Green, greenTextureUrl);
            Materials.AddBlockTexture(BlockColor.Black, blackTextureUrl);
            Materials.AddBlockTexture(BlockColor.Blue, blueTextureUrl);
            Materials.AddBlockTexture(BlockColor.Orange, orangeTextureUrl);
            Materials.AddBlockTexture(BlockColor.Purple, purpleTextureUrl);
            Materials.AddBlockTexture(BlockColor.Red, redTextureUrl);
            Materials.AddBlockTexture(BlockColor.White, whiteTextureUrl);
            Materials.AddBlockTexture(BlockColor.Yellow, yellowTextureUrl);

            //Materials.AddBlockTexture(BlockColor.Error, errorTextureUrl);
        }

        Materials.AddBlockTexture(BlockColor.Error, errorTextureUrl);
    }

    private static AddColor(name: number, hexColor: string): void {
        let red: number = parseInt(hexColor.substr(1, 2), 16) / 255.0;
        let green: number = parseInt(hexColor.substr(3, 2), 16) / 255.0;
        let blue: number = parseInt(hexColor.substr(5, 2), 16) / 255.0;

        var color = new Color3(red, green, blue);
        Materials.List[name] = new StandardMaterial("Material:" + name, Editor.Scene);
        Materials.List[name].diffuseColor = color;
        Materials.List[name].specularColor = color.subtract(new Color3(0.1, 0.1, 0.1));

        //Materials.ListEditor[name] = new BABYLON.StandardMaterial(name, EditorPreview.Instance.getScene());
        //Materials.ListEditor[name].diffuseColor = new BABYLON.Color3(red, green, blue);
    }

    private static AddBlockTexture(name: number, url: string): void {
        var material = new StandardMaterial("Material:" + name, Editor.Scene);
        material.diffuseTexture = new Texture(url, Editor.Scene);
        //material.emissiveColor = new BABYLON.Color3(red, green, blue);

        Materials.List[name] = material;
    }
}