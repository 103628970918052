import { Effect } from "../../Materials/effect";

let name = 'meshUboDeclaration';
let shader = `layout(std140,column_major) uniform;
uniform Mesh
{
mat4 world;
float visibility;
};
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var meshUboDeclaration = { name, shader };
