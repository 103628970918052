import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { Nullable } from "@babylonjs/core/types";
import { Vector3, Matrix, Vector4, Quaternion, Vector2 } from "@babylonjs/core/Maths/math.vector";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import { Light } from "@babylonjs/core/Lights";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Camera } from "@babylonjs/core/Cameras";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode"
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { LinesBuilder, Mesh, MeshBuilder, PlaneBuilder } from "@babylonjs/core/Meshes";
import { PointerInfo, PointerEventTypes } from "@babylonjs/core/Events";
import { Observer, EventState, Observable } from "@babylonjs/core/Misc/observable";
import { PickingInfo } from "@babylonjs/core/Collisions/pickingInfo";
//import { BoxBuilder } from "@babylonjs/core/Meshes/Builders/boxBuilder";
import { GroundBuilder } from "@babylonjs/core/Meshes/Builders/groundBuilder";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
//import { GridMaterial } from "@babylonjs/materials/grid/gridMaterial";
import { AxesViewer } from "@babylonjs/core/Debug/axesViewer"
import { BoundingBoxRenderer } from "@babylonjs/core/Rendering/boundingBoxRenderer";
import { OutlineRenderer } from "@babylonjs/core/Rendering/outlineRenderer" // mesh.renderOverlay
import { HighlightLayer } from "@babylonjs/core/Layers/highlightLayer";
import { EffectLayer } from "@babylonjs/core/Layers/effectLayer";
//import { EffectLayerSceneComponent } from "@babylonjs/core/Layers/effectLayerSceneComponent";
import '@babylonjs/core/Layers/effectLayerSceneComponent';


// If you don't need the standard material you will still need to import it since the scene requires it.
// import "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";

import { Meshes } from "./Meshes";
import { Materials } from "./Materials";
import { BlockColor, GameBlock } from "./GameBlock";
import { GameModel } from "./GameModel";
import { ModelData } from "./ModelData";
//import invert, { RGB, RgbArray, HexColor, BlackWhite } from './Invert';

//import sky1TextureUrl from "../../assets/sky1.jpg";
//import sky2TextureUrl from "../../assets/sky2.jpg";
//import sky3TextureUrl from "../../assets/sky3.jpg";
//import skyTextureUrl from "../../assets/sky.png";
//import groundTextureUrl from "../../assets/ground.png";
//import ground1TextureUrl from "../../assets/ground1.png";
//import ground2TextureUrl from "../../assets/ground2.png";
//import ground3TextureUrl from "../../assets/ground3.png";
import gridTextureUrl from "../../assets/grid.png";

import cancelUrl from "../../assets/cancel.png";
import okUrl from "../../assets/ok.png";
import cubeUrl from "../../assets/cube.png";

import { RefModelData, EditorData, PointerDragEvent } from "./EditorData";
import { Axis } from "@babylonjs/core/Maths/math";
import { EditorError, GameObject } from "./GameObject";
//import { DynamicTexture } from "@babylonjs/core/Materials/Textures/dynamicTexture";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
//import { AxisDragGizmo } from "@babylonjs/core/Gizmos/axisDragGizmo";
//import { PlaneRotationGizmo } from "@babylonjs/core/Gizmos/planeRotationGizmo";
//import { PositionGizmo } from "@babylonjs/core/Gizmos/positionGizmo";
//import { RotationGizmo } from "@babylonjs/core/Gizmos/rotationGizmo";
import { Tools } from "@babylonjs/core/Misc/tools";
import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
//import { PointerDragBehavior } from "@babylonjs/core/Behaviors/Meshes/pointerDragBehavior";
//import { FreeCamera } from "@babylonjs/core/Cameras/freeCamera";
import { Viewport } from "@babylonjs/core/Maths/math.viewport";
//import { Material } from "@babylonjs/core/Materials/material";
//import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
//import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Container, Button, Control, Line, Ellipse, Image as UIImgae, ColorPicker, Rectangle, TextBlock, MultiLine } from "@babylonjs/gui/2D/controls";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
//import { WebRequest } from "@babylonjs/core/Misc/webRequest";
import { DefaultLoadingScreen } from "@babylonjs/core/Loading/loadingScreen";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { Material } from "@babylonjs/core/Materials/material";
import { PrecisionDate } from "@babylonjs/core/Misc/precisionDate";
//import { ArcRotateCameraMouseWheelInputEx } from "./ArcRotateCameraMouseWheelInputEx";

//import { ScreenshotTools } from "@babylonjs/core/Misc/screenshotTools";
//import { EnvironmentHelper } from "@babylonjs/core/Helpers";

import { ExecuteEnum, ModelEnum } from "./ExecuteCmds";
import { ThinSprite } from "@babylonjs/core/Sprites/thinSprite";
import { DynamicTexture } from "@babylonjs/core/Materials/Textures/dynamicTexture";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { AxisDragGizmo } from "@babylonjs/core/Gizmos/axisDragGizmo";

export class Editor {
    private static _instance: Editor;

    public static _ScreenshotCanvas: HTMLCanvasElement;

    public static Canvas: HTMLCanvasElement;
    public static Engine: Engine;
    public static Scene: Scene;
    public static AmbientLight: Light;
    public static Camera: ArcRotateCamera; //Camera;
    public static Skybox: Mesh;
    public static Root: TransformNode;

    private static NameGround: string = "ground";
    private static NameGroundGrid: string = "grid";
    private static NameSky: string = "sky";
    private static TypeExt: string = ".qumeta";

    public static get Instance(): Editor { return Editor._instance; }

    private _secondCamera!: ArcRotateCamera;

    private _ground!: Mesh;
    private _groundGrid!: Mesh;
    private _coordinate!: TransformNode;
    private _coordinateOrign!: TransformNode;
    private _axesViewers: Array<AxesViewer> = [];
    private _gizmoManager!: GizmoManager;

    private _selectedList: Set<GameObject> = new Set<GameObject>();
    private _selectedObject:Nullable<GameObject> = null; // 最后一个选中，拖拽操作的对象
    private _blocks: Array<Set<GameObject>> = [];

    private _highlightLayer1!: HighlightLayer; // 高亮选择
    private _highlightLayer2!: HighlightLayer; // 高亮选择

    private _id: string = "";
    private _color: number = BlockColor.None;
    private _skyColor: string = "#262626";//"#33334C";
    private _groundColor: string = "#303030"; //(new Color3(0.5, 0.8, 0.5)).toHexString(); //"#ffffff";

    private _blockNums: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0]; // 使用块的个数
    private _modelNums: Map<string, number> = new Map(); // 使用模型的个数

    public static MaxRange = 101; // 一个byte最大存储255(内部最大251)
    public static MinRange = 3;
    private _range: number = 31; // 单个作品自身尺寸 引用的模型(原点)必须在位置 // 51 15
    private _rangeHalf: number = 15; //25;
    private _cropState: boolean = false; // 截屏状态
    private _previewFlag: boolean = false; // 预览状态(不能编辑,自动旋转)
    private _is3D: boolean = true; // 是否3D显示
    private _changePerspectiveFlag: boolean = false; // 是否3D用透视/正交切换相机(最好不用)

    private _animationFlag: boolean = true; // 是否用动画显示

    private _historyList: Array<any> = [];  // Undo/Redo数据
    private _executeCursor: number = 0;

    private _error: EditorError = EditorError.Ok;

    private _test: Array<number> = [];
    // 当前选择的块颜色（8类,如果为0则是选择模式）
    public set Color(value: BlockColor) {
        this._color = value;
        // dmeo test
        if (value > 0 && value < 4)
            this._test.push(value);
        else
            this._test = [];
    }
    public get Color(): BlockColor { return this._color; }

    // 天空颜色
    private setSkyColor(value: string) {
        this._skyColor = value;
        (<StandardMaterial>(Editor.Skybox.material)).emissiveColor = Color3.FromHexString(this._skyColor);

        // 颜色也缓存
        localStorage.setItem(`${this._id}${Editor.TypeExt}.SkyColor`, this._skyColor);
    }

    public set SkyColor(value: string) {
        if (this._skyColor != value) {
            // 保持前
            this.pushHistory({ type: ExecuteEnum.SkyColor, data: { old: this._skyColor, new: value } });

            this.setSkyColor(value);
        }
    }
    public get SkyColor(): string { return this._skyColor; }

    // 天空颜色
    private setGroundColor(value: string) {
        this._groundColor = value;
        (<StandardMaterial>(this._ground.material)).emissiveColor = Color3.FromHexString(this._groundColor);

        // 颜色也缓存
        localStorage.setItem(`${this._id}${Editor.TypeExt}.GroundColor`, this._groundColor);
    }

    public set GroundColor(value: string) {
        if (this._groundColor != value) {
            // 保持前
            this.pushHistory({ type: ExecuteEnum.GroundColor, data: { old: this._groundColor, new: value } });

            this.setGroundColor(value);
        }
    }
    public get GroundColor(): string { return this._groundColor; }

    // 裁切状态
    public set CropState(value: boolean) {
        if (this._cropState != value) {
            this._cropState = value;

            this.changeCropState();
        }
    }

    public get CropState(): boolean { return this._cropState; }

    // 是否透视/正交切换(更改到正交时，放大缩小需要额外控制暂时停用TODO)
    public get ChangePerspectiveFlag(): boolean { return this._changePerspectiveFlag; }
    //public set ChangePerspectiveFlag(value: boolean) { this._changePerspectiveFlag = value; }

    // 是否为3D相机视角
    public get Camera3DFlag(): boolean { return this._is3D; }
    public set Camera3DFlag(value: boolean) {
        if (this._is3D != value) {
            this._is3D = value;

            // 3D
            if (this._changePerspectiveFlag) {
                Editor.Camera.mode = this._is3D ? Camera.PERSPECTIVE_CAMERA : Camera.ORTHOGRAPHIC_CAMERA;
            }

            this.ResetCamera();
        }
    }

    // 范围
    public get Range(): number { return this._range; }

    // 设定范围
    public set Range(value: number) {
        if (value < Editor.MinRange) {
            value = Editor.MinRange;
            return;
        }

        // 奇数
        if (value % 2 == 0)
            return;

        if (value > Editor.MaxRange)
            value = Editor.MaxRange;

        if (this._range != value) {
            this._range = value;
            this._rangeHalf = Math.floor(this._range / 2);

            if (this._ground) {
                //var groundMaterial = this._ground.material as StandardMaterial;
                //(groundMaterial.diffuseTexture as Texture).uScale = this._range / 4;
                //(groundMaterial.diffuseTexture as Texture).vScale = this._range / 4;

                this._ground.scaling = new Vector3(this._range, 1, this._range);
                this._groundGrid.scaling = new Vector3(this._range, 1, this._range);
            }

            for (var i = 0; i < Editor.MaxRange * Editor.MaxRange * Editor.MaxRange; i++) {
                if (this._blocks[i].size > 0)
                    this._blocks[i].clear();
            }

            this.checkGameObject();

            // TODO 历史暂不支持修改区域
            // 区域改变，坐标会有修改，清除历史
            this._historyList = [];
            this._executeCursor = 0;
        }
    }

    // 当前是播放创建/销毁动画
    public get AnimationFlag(): boolean { return this._animationFlag; }
    public set AnimationFlag(value: boolean) { this._animationFlag = value; }

    // 当前是否错误
    public get Error(): EditorError { return this._error; }

    private pointerObserver: Nullable<Observer<PointerInfo>> = null;

    private dragStartObserver: Nullable<Observer<unknown>> = null;
    private dragEndObserver: Nullable<Observer<unknown>> = null;
    private dragObserverX: Nullable<Observer<PointerDragEvent>> = null;
    private dragObserverY: Nullable<Observer<PointerDragEvent>> = null;
    private dragObserverZ: Nullable<Observer<PointerDragEvent>> = null;

    constructor(canvasElement: string,
        private onBlockChange?: (nums: Array<number>) => void,
        private onSelected?: (move: boolean, rotate: boolean) => void,
        private onScreenShot?: (id: string, name?: string, data?: string) => void,
        private onUndoRedo?: (undo: boolean, redo: boolean) => void,
        private onModelChange?: (nums: Map<string, number>) => void) {
        Editor._instance = this;

        Editor.Canvas = document.getElementById(canvasElement) as HTMLCanvasElement;
        Editor.Engine = new Engine(Editor.Canvas, true, { preserveDrawingBuffer: true, stencil: true }, false);

        // 最大位置物体索引
        for (var i = 0; i < Editor.MaxRange * Editor.MaxRange * Editor.MaxRange; i++) {
            this._blocks[i] = new Set<GameObject>();
        }
    }

    public async initialize(): Promise<void> {
        await this.initializeScene();
    }

    private async initializeScene(): Promise<void> {
        Editor.Scene = new Scene(Editor.Engine);

        this.initializeCamera();
        Editor.Camera.minZ = 0.2;
        Editor.Camera.maxZ = 2000;

        this.createLights();

        // Skybox seed : 1vt3h8rxhb28
        Editor.Skybox = MeshBuilder.CreateSphere(Editor.NameSky, { diameter: 3000.0 }, Editor.Scene);
        Editor.Skybox.layerMask = 1;
        Editor.Skybox.infiniteDistance = true;
        let skyboxMaterial: StandardMaterial = new StandardMaterial(Editor.NameSky, Editor.Scene);
        skyboxMaterial.backFaceCulling = false;
        //skyboxMaterial.emissiveTexture = new Texture(sky1TextureUrl, Editor.Scene);
        //skyboxMaterial.diffuseTexture = " 5DFAC5"new Texture(sky3TextureUrl, Editor.Scene);
        skyboxMaterial.emissiveColor = Color3.FromHexString(this.SkyColor); //Color3.FromHexString("#3C97CF"); // "3C97CF" 
        skyboxMaterial.diffuseColor = Color3.Black(); //Color3.FromHexString("#5DFAC5"); //Color3.Black(); //new Color3(0, 0, 0);
        skyboxMaterial.specularColor = Color3.Black(); //Color3.FromHexString("#D449CD");// Color3.Black(); //new Color3(0, 0, 0);
        Editor.Skybox.material = skyboxMaterial;

        // 创建场景后创建
        Materials.Initialize();
        Meshes.Initialize();

        this.pointerObserver = Editor.Scene.onPointerObservable.add(this.onPointer);

        this.createGroud();

        this.addCoordinateSight(Editor.Scene)

        this.createAxis();

        this.createHighlight(Editor.Scene);

        this.createUI();

        // Default Environment
        //var environment = Editor.Scene.createDefaultEnvironment({ enableGroundShadow: true, groundYBias: 2.8 });
        //environment!.setMainColor(Color3.FromHexString("#74b9ff"))

        // Enable VR
        //var vrHelper = scene.createDefaultVRExperience({createDeviceOrientationCamera:false, useXR: true});
        //vrHelper.enableTeleportation({floorMeshes: [environment.ground]});

        this.animate();
    }

    private createLights() {
        // 环境光
        var direction = new Vector3(0, 1, 0); // new Vector3(0, 1, 0); // new Vector3(0, 35, -35);
        var ambientLight = new HemisphericLight("AmbientLight", direction, Editor.Scene);
        //ambientLight.diffuse = Color3.FromHexString("#FFFFFF"); // Diffuse gives the basic color to an object;
        ambientLight.specular = Color3.Black();// Color3.FromHexString("#FFFFFF"); // Specular produces a highlight color on an object.
        ambientLight.groundColor = Color3.FromHexString("#FFFFFF"); // The groundColor is the light in the opposite direction to the one specified during creation.
        ambientLight.intensity = 0.6; // 0.6; // Strength of the light. [0,1]
        //Editor.Light.specular = Color3.Black(); //new Color3(0, 0, 0);
        Editor.AmbientLight = ambientLight;

        // 方向光
        direction = new Vector3(600, -1200, -600); // new Vector3(0, -1, 0)
        var directionalLight = new DirectionalLight("DirectionalLight", direction, Editor.Scene);
        //directionalLight.diffuse = Color3.FromHexString("#FFFFFF"); // White
        directionalLight.specular = Color3.Black();// Color3.FromHexString("#FFFFFF");
        directionalLight.intensity = 0.6; // 0.6;
        //directionalLight.position.set(145, 55, -91);
        //Editor.DirectionalLight = directionalLight;
    }

    // [min, max)
    private getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
    }

    private getRandomBlocks(num: number, rand: number = 0): Array<Vector4> {
        // 随机一些块
        var blocks = [];
        //var num = 10;
        for (var x = 0; x < num; x++) {
            for (var y = 0; y < num; y++) {
                for (var z = 0; z < num; z++) {
                    if (Math.random() > rand) {
                        blocks.push(new Vector4(
                            this.getRandomInt(-num, num + 1),
                            this.getRandomInt(0, 2 * num + 1),
                            this.getRandomInt(-num, num + 1),
                            this.getRandomInt(0, 8)));
                    }
                }
            }
        }

        return blocks;
    }

    private animate(): void {
        var divFps = document.getElementById("fps");

        Editor.Engine.runRenderLoop(() => {
            Editor.Scene.render();

            if (divFps)
                divFps.innerHTML = Editor.Engine.getFps().toFixed() + " fps";
        });

        window.addEventListener("resize", () => {
            Editor.Engine.resize();

            Editor.Instance.reizeUI();
        });
    }

    private initializeCamera(): void {
        let camera = new ArcRotateCamera("ArcRotateCamera", 0, 0, 0, new Vector3(0, 0, 0), Editor.Scene);
        camera.layerMask = 0x0FFFFFFF;
        camera.setTarget(Vector3.Zero());
        // 最大范围
        camera.lowerBetaLimit = 0;
        camera.upperBetaLimit = Tools.ToRadians(90); //Tools.ToRadians(85);

        // 视野半径范围
        camera.lowerRadiusLimit = 6;
        camera.upperRadiusLimit = 200;

        // 相机初始位置
        //camera.setPosition(new Vector3(0, 50, -50));

        camera.wheelPrecision *= 4;
        //camera.wheelDeltaPercentage =0;

        // 鼠标输入控制
        //camera.inputs.removeByType("ArcRotateCameraMouseWheelInput");
        //camera.inputs.add(new ArcRotateCameraMouseWheelInputEx());

        camera.attachControl(Editor.Canvas, true);
        Editor.Camera = camera;

        this.ResetCamera();
    }

    private addCoordinateSight(scene: Scene) {

        let secondCamera = new ArcRotateCamera("SecondCamera", 0, 0, 0, new Vector3(0, 0, 0), Editor.Scene);
        //var secondCamera = new FreeCamera("SecondCamera", new Vector3(0, 5, 0), scene);
        //secondCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        secondCamera.layerMask = 0x10000000;
        secondCamera.setTarget(Vector3.Zero());

        secondCamera.alpha = Editor.Camera.alpha;
        secondCamera.beta = Editor.Camera.beta;
        secondCamera.radius = 6; // camera.radius 50

        if (scene.activeCameras?.length === 0) {
            scene.activeCameras.push(Editor.Camera);
        }

        // 如果添加则会影响移动gizmos有bug
        if (scene.activeCameras) {
            //scene.activeCameras.push(secondCamera);
        }

        scene.cameraToUseForPointers = Editor.Camera;

        // 占屏幕比例 10%(宽高比TODO)
        let range = 0.2;

        // Specify location and amount of screen each camera should take
        // Note: All values for the viewport are going to be 0 to 1.  Think about it as a percentage of the screen.
        secondCamera.viewport = new Viewport(1 - range, 1 - range, range, range);

        // 场景原点坐标轴
        this._coordinateOrign = new TransformNode("orign", scene);
        this._axesViewers[0] = new AxesViewer(UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        //this._axesViewers[0] = new AxesViewer(Editor.Scene);
        this._axesViewers[0].update(Vector3.ZeroReadOnly, Vector3.Right(), Vector3.Up(), Vector3.Forward());
        this._axesViewers[0].xAxis.setParent(this._coordinateOrign);
        this._axesViewers[0].yAxis.setParent(this._coordinateOrign);
        this._axesViewers[0].zAxis.setParent(this._coordinateOrign);
        this._coordinateOrign.position.y = -0.5;

        // 小地图坐标轴
        this._coordinate = new TransformNode("coordinate", scene);
        //this._coordinate.setParent(secondCamera);

        this._axesViewers[1] = new AxesViewer(UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        //this._axesViewers[1] = new AxesViewer(Editor.Scene);
        this._axesViewers[1].update(Vector3.ZeroReadOnly, Vector3.Right(), Vector3.Up(), Vector3.Forward());

        this._axesViewers[1].xAxis.setParent(this._coordinate);
        this._axesViewers[1].yAxis.setParent(this._coordinate);
        this._axesViewers[1].zAxis.setParent(this._coordinate);

        this._axesViewers[1].xAxis.getChildMeshes().forEach((mesh) => {
            mesh.layerMask = 0x10000000;
        });
        this._axesViewers[1].yAxis.getChildMeshes().forEach((mesh) => {
            mesh.layerMask = 0x10000000;
        });
        this._axesViewers[1].zAxis.getChildMeshes().forEach((mesh) => {
            mesh.layerMask = 0x10000000;
        });

        Editor.Camera.onViewMatrixChangedObservable.add((ev: Camera, es: EventState) => {
            secondCamera.alpha = Editor.Camera.alpha;
            secondCamera.beta = Editor.Camera.beta;
        });

        this._secondCamera = secondCamera;
    }

    public dispose() {
        //this._gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragObservable.remove(this.dragObserverX);
        //this._gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragObservable.remove(this.dragObserverY);
        //this._gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragObservable.remove(this.dragObserverZ);
        //this._gizmoManager.gizmos.positionGizmo?.onDragStartObservable.remove(this.dragStartObserver);
        //this._gizmoManager.gizmos.positionGizmo?.onDragEndObservable.remove(this.dragEndObserver);

        Editor.Engine.stopRenderLoop();

        Editor.Engine.dispose();
    }

    private onPointer = (pointerInfo: PointerInfo, eventState: EventState) => {
        // 预览模式不做点击
        if (this._previewFlag)
            return;

        switch (pointerInfo.type) {
            case PointerEventTypes.POINTERDOWN: {
                // 截屏状态不操作增减
                if (!this._cropState && pointerInfo.event.button == 0) {
                    if (this._color == BlockColor.None)
                        this.selectGameObjectAtPos(pointerInfo.pickInfo);
                    else
                        this.createGameItemAtPos(pointerInfo.pickInfo);

                    // 不允许
                    //this.deleteGameObjectAtPos(pointerInfo.pickInfo);
                }
                if (pointerInfo.event.button == 2) {
                    this.deleteGameObjectAtPos(pointerInfo.pickInfo);
                }
                break;
            }
            case PointerEventTypes.POINTERDOUBLETAP:
                if (this._cropState) {
                    console.log("双击截图");
                    this.Screenshot((id, name, data) => {
                        this.CropState = false;

                        if (this.onScreenShot)
                            this.onScreenShot(id, name, data);
                    }, true);
                }
                break;
        }
    }

    private _clickNum: number = 0
    private _lastCoordinates: Nullable<Vector3> = null;

    private async createGameItemAtPos(pickInfo: Nullable<PickingInfo>): Promise<void> {
        // 如果已经有错时，不允许追加
        // 错误后可以继续操作，如果不允许追加，则需要有个明确的提示
        if (this._error != 0) {
            console.error("block position error!");
            return;
        }

        let pickedMesh = pickInfo?.pickedMesh;
        if (pickedMesh && pickInfo?.pickedPoint) {

            let localPickedPoint = pickInfo?.pickedPoint;
            let coordinate: Nullable<Vector3> = null;

            // groud 0, -0.5, 0
            // 0, 0, 0
            //console.log(pickedMesh.name);

            if (pickedMesh.name == Editor.NameGround || pickedMesh.name == Editor.NameGroundGrid) {
                localPickedPoint = localPickedPoint.subtract(pickedMesh.position);
                coordinate = new Vector3(
                    Math.round(localPickedPoint.x),
                    Math.round(localPickedPoint.y),
                    Math.round(localPickedPoint.z)
                );

                //console.log("x:" + coordinates.x + " z:" + coordinates.z);
            } else if (GameBlock.MathName(pickedMesh.name)
                || GameModel.MathName(pickedMesh.name)) {
                // 世界坐标
                var pos = pickedMesh.getAbsolutePosition();
                coordinate = new Vector3(
                    pos.x,
                    pos.y,
                    pos.z
                );

                let n = pickInfo.getNormal()!;
                //console.log(n);

                let absN = new Vector3(
                    Math.abs(n.x),
                    Math.abs(n.y),
                    Math.abs(n.z)
                );

                if (absN.x > absN.y && absN.x > absN.z) {
                    if (n.x > 0) {
                        coordinate.x++;
                    }
                    else {
                        coordinate.x--;
                    }
                }
                if (absN.y > absN.x && absN.y > absN.z) {
                    if (n.y > 0) {
                        coordinate.y++;
                    }
                    else {
                        coordinate.y--;
                    }
                }
                if (absN.z > absN.x && absN.z > absN.y) {
                    if (n.z > 0) {
                        coordinate.z++;
                    }
                    else {
                        coordinate.z--;
                    }
                }
            }

            // TODO 范围判断
            if (coordinate) {
                // 在20帧内并且同一个坐标失败
                if ((Editor.Scene.getFrameId() - this._clickNum) < 20
                    && this._lastCoordinates?.equals(coordinate)) {
                    console.error("same position!" + (Editor.Scene.getFrameId() - this._clickNum));
                    return;
                }

                this._lastCoordinates = coordinate.clone();
                this._clickNum = Editor.Scene.getFrameId();

                // 模拟快速点击同一个位置出现多个mesh
                //new GameBlock(coordinates, this._color);
                /*var block =*/ new GameBlock(coordinate, this._color);

                // 保持前
                this.pushHistory({ type: ExecuteEnum.AddGameBlock, position: coordinate, data: this._color });
            }
        }
    }

    private pushHistory(data: any) {
        // TODO 优化点 如果是针对位置，则可以清楚相邻的同类型，同位置的操作(Excel同Cell格式操作可叠加)
        /*
        if (this._executeCursor > 0) {
            var last = this._historyList[this._historyList.length - 1];
            if (last.type == data.type) {
                if (data.type == ExecuteEnum.AddGameBlock && last.position == data.position) {
                    var item = this._historyList.pop();
                    console.log(item);
                }
            }
        }
        */

        // 方案：用户主动操作后，删除游标到最后的操作历史
        this._historyList.splice(this._executeCursor, this._historyList.length - this._executeCursor);

        this._historyList.push(data);
        this._executeCursor = this._historyList.length;
        this.updateHistory();
    }

    // TODO 暂时不缓存
    private updateHistory() {
        // 保持一定数量历史比如100条，超过时删除最前面的记录
        //this._historyList.

        this.saveCached();

        if (this.onUndoRedo) {
            this.onUndoRedo((this._executeCursor > 0), (this._executeCursor < this._historyList.length));
        }
    }

    // (测试调用)创建一个格子
    public TestAddBlock(coordinates: Vector3, color: BlockColor) {
        new GameBlock(coordinates, color, true, true, false);
    }

    // (测试调用)创建一个模型
    public TestAddModel(modelData: ModelData) {
        // TODO 找到一个合适的坐标
        var coordinates = Vector3.Zero();
        new GameModel(coordinates, modelData);
    }

    // (UI界面调用)引入参考模型()
    public ImportRefModel(id: string, data: string /* ref ModelData */, num: number = 1): boolean {
        for (var i = 0; i < num; i++) {
            // 模拟编辑过了
            var gameModel = this.addModel(id, data);
            //console.log(gameModel.Pos);

            // 保持前
            this.pushHistory({ type: ExecuteEnum.AddGameModel, position: gameModel.Pos, data: gameModel.Data });

            this.checkGameObject();
        }

        //this.checkGameObject();

        return true;
    }

    // 创建之后的回调(因为当前可能播放创建动画)
    public OnGameObjectCreated(gameObject: Nullable<GameObject>) {
        if (gameObject) {
            //var that = this;

            // gameObject.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
            //     //console.log(x + " " + y + " " + z + " " + mesh.name);
            //     that._blocks[that.cubeIndex(x, y, z)].add(gameObject!);
            // });

            this.checkGameObject();
            this.calBlockNum();
        }
    }

    // 坐标转成(range范围的)索引
    private cubeIndex(x: number, y: number, z: number): number {
        // 0,0,0
        // Editor.MaxRange 251 (-126,126)
        // rang 15 (-7,7)
        // this._rangeHalf = (this._range - 1) / 2
        var _x = x + this._rangeHalf; // -7 + 7 --> 0
        var _z = z + this._rangeHalf;

        return y * this._range * this._range + _x * this._range + _z;
    }

    private modifyRange() {
        // 范围检测
        this._selectedList.forEach((item) => {
            //const boundingBox = item.Mesh.getBoundingInfo().boundingBox;

            //const min = 0;// + (boundingBox.centerWorld.y - boundingBox.minimumWorld.y);
            //const max = Editor.MaxRange;// + (boundingBox.centerWorld.y - boundingBox.maximumWorld.y);
            //console.log(boundingBox);
            //console.log(min + " " + max);

            if (item.Mesh.position.y <= 0)
                item.Mesh.position.y = 0;
            else if (item.Mesh.position.y >= this._range)
                item.Mesh.position.y = this._range;

            if (item.Mesh.position.x <= -this._rangeHalf)
                item.Mesh.position.x = -this._rangeHalf;
            else if (item.Mesh.position.x >= this._rangeHalf)
                item.Mesh.position.x = this._rangeHalf;

            if (item.Mesh.position.z <= -this._rangeHalf)
                item.Mesh.position.z = -this._rangeHalf;
            else if (item.Mesh.position.z >= this._rangeHalf)
                item.Mesh.position.z = this._rangeHalf;
        });
    }

    private onDrag = (e: PointerDragEvent) => {
        // 拖拽时限制坐标在范围内
        this.modifyRange();
    }

    private onDragStart = () => {
        console.log("onDragStart");

        if (this._selectedObject) {
            console.log(this._selectedObject.Mesh.position);
        }

        //if(this._gizmoManager.gizmos.positionGizmo?.attachedMesh){
        //    console.log(this._gizmoManager.gizmos.positionGizmo?.attachedMesh.name);
        //}
    }

    private onDragEnd = () => {
        // 坐标整数化？
        console.log("onDragEnd");

        var items: Array<any> = [];

        if (this._selectedObject) {
            var newPos = this._selectedObject.Mesh.position;

            if (this._selectedObject instanceof GameBlock) {
                var item = this._selectedObject;

                if (!newPos.equals(this._selectedObject.Pos)) {
                    if (newPos.x != this._selectedObject.Pos.x)
                        items.push({ type: ModelEnum.Block, position: newPos.clone(), data: { axis: Axis.X, forword: (newPos.x - this._selectedObject.Pos.x), data: item.Color, from:item.Pos.clone() } });
                    else if (newPos.y != this._selectedObject.Pos.y)
                        items.push({ type: ModelEnum.Block, position: newPos.clone(), data: { axis: Axis.Y, forword: (newPos.y - this._selectedObject.Pos.y), data: item.Color, from:item.Pos.clone() } });
                    else if (newPos.z != this._selectedObject.Pos.z)
                        items.push({ type: ModelEnum.Block, position: newPos.clone(), data: { axis: Axis.Z, forword: (newPos.z - this._selectedObject.Pos.z), data: item.Color, from:item.Pos.clone() } });

                    this._selectedObject.Pos.copyFrom(newPos);
                }
            }
            else if (this._selectedObject instanceof GameModel) {
                //this._selectedObject.Pos
                var model = this._selectedObject;
                if (!newPos.equals(this._selectedObject.Pos)) {
                    if (newPos.x != this._selectedObject.Pos.x)
                        items.push({ type: ModelEnum.Model, position: newPos.clone(), data: { axis: Axis.X, forword: (newPos.x - this._selectedObject.Pos.x), data: model.Data, from:model.Pos.clone() } });
                    else if (newPos.y != this._selectedObject.Pos.y)
                        items.push({ type: ModelEnum.Model, position: newPos.clone(), data: { axis: Axis.Y, forword: (newPos.y - this._selectedObject.Pos.y), data: model.Data, from:model.Pos.clone() } });
                    else if (newPos.z != this._selectedObject.Pos.z)
                        items.push({ type: ModelEnum.Model, position: newPos.clone(), data: { axis: Axis.Z, forword: (newPos.z - this._selectedObject.Pos.z), data: model.Data, from:model.Pos.clone() } });

                    this._selectedObject.Pos.copyFrom(newPos);
                }
            }
        }

        //this._selectedList.forEach((item) => {});
        
        // 保持前
        this.pushHistory({ type: ExecuteEnum.Move, items: items });

        this.checkGameObject();
    }

    private deleteGameObjectAtPos(pickInfo: Nullable<PickingInfo>): void {
        let pickedMesh = pickInfo?.pickedMesh;

        var items: Array<any> = [];

        if (pickedMesh) {
            let findModel = GameModel.FindByMesh(pickedMesh)
            if (findModel) {
                // var that = this;
                // findModel.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                //     that._blocks[that.cubeIndex(x, y, z)].delete(findModel!);
                // });

                items.push({ type: ModelEnum.Model, position: findModel.Pos, data: findModel.Data });

                this.unSelectGameObject(findModel);
                findModel.Dispose();
            }

            let find = GameBlock.FindByMesh(pickedMesh)
            if (find) {
                // var that = this;
                // find.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                //     that._blocks[that.cubeIndex(x, y, z)].delete(find!);
                // });

                items.push({ type: ModelEnum.Block, position: find.Pos, data: find.Color });

                this.unSelectGameObject(find);
                find.Dispose();
            }
        }

        // 保持前
        this.pushHistory({ type: ExecuteEnum.Delete, items: items });

        this.checkGameObject();
        this.calBlockNum();
    }

    private selectGameObjectAtPos(pickInfo: Nullable<PickingInfo>): void {
        // 选择先取消选择，多选时在考虑方案
        this.UnSelectAll();

        let pickedMesh = pickInfo?.pickedMesh;

        if (pickedMesh) {
            if (Editor.NameGround == pickedMesh.name || Editor.NameGroundGrid == pickedMesh.name) {
                this.UnSelectAll();
            }
            else {
                console.log(pickedMesh.name);

                let findModel = GameModel.FindByMesh(pickedMesh)
                if (findModel) {
                    findModel.Selected = !findModel.Selected;
                    findModel.Selected ? this.selectGameObject(findModel) : this.unSelectGameObject(findModel);
                    return;
                }

                let find = GameBlock.FindByMesh(pickedMesh)
                if (find) {
                    find.Selected = !find.Selected;
                    find.Selected ? this.selectGameObject(find) : this.unSelectGameObject(find);
                    return;
                }
            }
        } else {
            console.log("pick null");
        }
    }

    private selectGameObject(object: GameObject) {
        this._selectedList.add(object);
        this.updateAxis();

        // 格子没有旋转
        //this._gizmoManager.rotationGizmoEnabled = !GameBlock.MathName(object.Mesh.name);
        this._gizmoManager.attachToMesh(object.Mesh);
        this._selectedObject = object;

        this._textEllipses.forEach(item => {
            item.isVisible = true;
        });
        //console.log("选中：" + object.Mesh.name);
    }

    private unSelectGameObject(object: GameObject) {
        this._selectedList.delete(object);
        this.updateAxis();

        this._gizmoManager.attachToMesh(null);
        this._textEllipses.forEach(item => {
            item.isVisible = false;
        });
    }

    private updateAxis() {
        this._coordinateOrign.setEnabled(!this._previewFlag && this._selectedList.size == 0);
        if (this._selectedList.size == 0) {
            Editor.Scene.addCamera(this._secondCamera);

            // 第二个相机，影响gizmo操作有bug所以选择时关闭
            if (Editor.Scene.activeCameras?.length == 1)
                Editor.Scene.activeCameras?.push(this._secondCamera);
        }
        else {
            Editor.Scene.removeCamera(this._secondCamera);
        }

        this._gizmoManager.attachableMeshes = [];

        this._selectedList.forEach((item) => {
            if (this._gizmoManager.attachableMeshes)
                this._gizmoManager.attachableMeshes.push(item.Mesh);
        });

        // 移动按钮和旋转按钮是否可操作
        if (this.onSelected) {
            var move: boolean = (this._selectedList.size > 0);
            var rotate: boolean = (this._selectedList.size == 1);

            if (rotate) {
                // 模型才能旋转
                this._selectedList.forEach((item) => {
                    rotate = !GameBlock.MathName(item.Mesh.name)
                });
            }

            this.onSelected(move, rotate);
        }
    }

    private createGroud() {
        Editor.Root = new TransformNode("Root", Editor.Scene);

        // Our built-in 'ground' shape.
        var showGrid = false;

        if (showGrid) {
            // var gridGround = Mesh.CreateGround(Editor.NameGround, 200, 200, 5, Editor.Scene);

            // var grid = new GridMaterial("grid", Editor.Scene);
            // grid.gridRatio = 1;
            // grid.lineColor = Color3.FromInts(42, 87, 154);
            // grid.mainColor = Color3.FromInts(230, 230, 230);
            // grid.majorUnitFrequency = 1;
            // gridGround.material = grid;

            // gridGround.position.y = -0.5;
        } else {
            var ground = GroundBuilder.CreateGround(
                Editor.NameGround,
                { width: 1, height: 1 },
                Editor.Scene
            );

            ground.position.y = -0.52; // 使0,0,0的box刚好在地表上

            // Load a texture to be used as the ground material
            const groundMaterial = new StandardMaterial("ground material", Editor.Scene);
            
            groundMaterial.emissiveColor = Color3.FromHexString(this.GroundColor);//Color3.FromHexString("#666666");// Color3.FromHexString(this.GroundColor);
            groundMaterial.diffuseColor = Color3.Black(); //Color3.FromHexString("#447C99");//// Color3.Black(); //new Color3(0, 0, 0);
            groundMaterial.specularColor = Color3.Black();//Color3.FromHexString("#8381FF"); //Color3.Black(); //new Color3(0, 0, 0);
            ground.material = groundMaterial;
            ground.scaling = new Vector3(this._range, 1, this._range);

            this._ground = ground;

            var groundGrid = GroundBuilder.CreateGround(
                Editor.NameGroundGrid,
                { width: 1, height: 1 },
                Editor.Scene
            );

            groundGrid.position.y = -0.51; // 使0,0,0的box刚好在地表上

            // Load a texture to be used as the ground material
            const groundGridMaterial = new StandardMaterial("ground grid material", Editor.Scene);
            //var texture = new Texture(ground1TextureUrl, Editor.Scene);
            var texture = new Texture(gridTextureUrl, Editor.Scene);
            texture.uScale = this._range / 1;
            texture.vScale = this._range / 1;
            //texture.uOffset = 0.5; // 格子偏移
            //texture.vOffset = 0.5;
            //texture.hasAlpha = true;
            
            groundGridMaterial.opacityTexture = texture;
            groundGrid.material = groundGridMaterial;
            groundGrid.scaling = new Vector3(this._range, 1, this._range);

            this._groundGrid = groundGrid;

            // x- x+ z- z+
            /*
            var x1Plane = MeshBuilder.CreatePlane(Editor.NameGroundGrid+":x-", { size: this._range });
            x1Plane.material = groundGridMaterial;
            x1Plane.position = new Vector3(-this._range/2, this._range / 2, 0);
            x1Plane.rotationQuaternion = Quaternion.FromEulerAngles(0, Tools.ToRadians(90), 0);

            var x2Plane = MeshBuilder.CreatePlane(Editor.NameGroundGrid+":x+", { size: this._range });
            x2Plane.material = groundGridMaterial;
            x2Plane.position = new Vector3(this._range/2, this._range / 2, 0);
            x2Plane.rotationQuaternion = Quaternion.FromEulerAngles(0, Tools.ToRadians(90), 0);

            var z1Plane = MeshBuilder.CreatePlane(Editor.NameGroundGrid+":z-", { size: this._range });
            z1Plane.material = groundGridMaterial;
            z1Plane.position = new Vector3(0, this._range / 2, -this._range/2);
            z1Plane.rotationQuaternion = Quaternion.FromEulerAngles(Tools.ToRadians(90), 0, 0);

            var z2Plane = MeshBuilder.CreatePlane(Editor.NameGroundGrid+":z+", { size: this._range });
            z2Plane.material = groundGridMaterial;
            z2Plane.position = new Vector3(0, this._range / 2, this._range/2);
            z2Plane.rotationQuaternion = Quaternion.FromEulerAngles(Tools.ToRadians(90), 0, 0);
            */
        }

        // TODO for test demo
        //this.createDemo();
    }

    private createDemo() {
        //new GameBlock(new Vector3(0, 0, 0), 0);

        // 创建一个模型
        //var str = Meshes.Instance.ListJson[this._color];
        //var editData = JSON.parse(str) as EditorData;

        //var data = new ModelData("group:" + this._color, editData.Blocks);
        //new GameModel(new Vector3(6, 0, 3), data);

        // 不支持拖拽，1，坐标是整数单位 2，拖动相机和拖动移动模型冲突
        //var pointerDragBehavior = new PointerDragBehavior({ dragAxis: Axis.X });
        //pointerDragBehavior.dragDeltaRatio = 1;
        //GameModel.Instances[0].Mesh.addBehavior(pointerDragBehavior);
    }

    private createAxis() {
        // Create utility layer the gizmo will be rendered on
        UtilityLayerRenderer.DefaultUtilityLayer.setRenderCamera(Editor.Camera);

        //var utilLayer = new UtilityLayerRenderer(Editor.Scene);
        //utilLayer.setRenderCamera(Editor.Camera);

        var utilLayer = UtilityLayerRenderer.DefaultUtilityLayer;
        {
            //var gizmoManager = new GizmoManager(Editor.Scene, 1, utilLayer);
            var gizmoManager = new GizmoManager(utilLayer.utilityLayerScene, 1, utilLayer);
            
            gizmoManager.positionGizmoEnabled = true;
            gizmoManager.rotationGizmoEnabled = false;
            gizmoManager.scaleGizmoEnabled = false;
            gizmoManager.boundingBoxGizmoEnabled = false;
            gizmoManager.usePointerToAttachGizmos = false;

            if (gizmoManager.gizmos.positionGizmo) {
                gizmoManager.gizmos.positionGizmo.updateGizmoRotationToMatchAttachedMesh = false;
                gizmoManager.gizmos.positionGizmo.snapDistance = 1;
                gizmoManager.gizmos.positionGizmo.scaleRatio = 1;
                // gizmoManager.gizmos.positionGizmo.planarGizmoEnabled = true; // 不好做到格子话
                this.dragObserverX = gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(this.onDrag);
                this.dragObserverY = gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(this.onDrag);
                this.dragObserverZ = gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(this.onDrag);
                this.dragStartObserver = gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(this.onDragStart);
                this.dragEndObserver = gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(this.onDragEnd);
            }
            // if (gizmoManager.gizmos.rotationGizmo) {
            //     gizmoManager.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
            //     gizmoManager.gizmos.rotationGizmo.snapDistance = Tools.ToRadians(90);
            //     gizmoManager.gizmos.rotationGizmo.scaleRatio = 0.5;
            // }

            //gizmoManager.positionGizmoEnabled = false;
            //gizmoManager.rotationGizmoEnabled = false;

            //gizmoManager.attachableMeshes = [GameModel.Instances[0].Mesh];
            //gizmoManager.attachToMesh(GameModel.Instances[0].Mesh);

            this._gizmoManager = gizmoManager;
        }

        // show x-y-z
        // show axis
        var showAxis = function (size: number, len: number, scene: Scene, parent: TransformNode) {
            var makeTextPlane = function (text: string, color: string, s: number) {
                var dynamicTexture = new DynamicTexture("DynamicTexture", 50, scene, true);
                dynamicTexture.hasAlpha = true;
                dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color, "transparent", true);
                var plane = MeshBuilder.CreatePlane("TextPlane", { size: s });
                var material = new StandardMaterial("TextPlaneMaterial", scene);
                material.backFaceCulling = false;
                material.specularColor = new Color3(0, 0, 0);
                material.diffuseTexture = dynamicTexture;

                plane.material = material
                return plane;
            };

            // var axisX = MeshBuilder.CreateLines("axisX", {
            //     points: [
            //         Vector3.Zero(), new Vector3(size, 0, 0), new Vector3(size * 0.95, 0.05 * size, 0),
            //         new Vector3(size, 0, 0), new Vector3(size * 0.95, -0.05 * size, 0)
            //     ]
            // }, scene);
            // axisX.color = new Color3(1, 0, 0);
            var xChar = makeTextPlane("X", "red", size / 10);
            xChar.position = new Vector3(0.9 * len, 0, 0);
            xChar.setParent(parent);

            // var axisY = MeshBuilder.CreateLines("axisY", {
            //     points: [
            //         Vector3.Zero(), new Vector3(0, size, 0), new Vector3(-0.05 * size, size * 0.95, 0),
            //         new Vector3(0, size, 0), new Vector3(0.05 * size, size * 0.95, 0)
            //     ]
            // }, scene);
            // axisY.color = new Color3(0, 1, 0);
            var yChar = makeTextPlane("Y", "green", size / 10);
            yChar.position = new Vector3(0, 0.6 * len, 0);
            yChar.setParent(parent);

            // var axisZ = MeshBuilder.CreateLines("axisZ", {
            //     points: [
            //         Vector3.Zero(), new Vector3(0, 0, size), new Vector3(0, -0.05 * size, size * 0.95),
            //         new Vector3(0, 0, size), new Vector3(0, 0.05 * size, size * 0.95)
            //     ]
            // }, scene);
            // axisZ.color = new Color3(0, 0, 1);
            var zChar = makeTextPlane("Z", "blue", size / 10);
            zChar.position = new Vector3(0, 0, 0.9 * len);
            zChar.setParent(parent);
        };

        showAxis(5, 2, Editor.Scene, this._coordinateOrign);

        this.updateAxis();
    }

    private createHighlight(scene: Scene) {
        this._highlightLayer1 = new HighlightLayer("HighlightLayer", scene, { camera: Editor.Camera });

        this._highlightLayer1.blurVerticalSize = 1;
        this._highlightLayer1.blurHorizontalSize = 1;
        this._highlightLayer1.outerGlow = false;
        this._highlightLayer1.innerGlow = true;

        this._highlightLayer2 = new HighlightLayer("HighlightLayer", scene, { camera: Editor.Camera });

        this._highlightLayer2.blurVerticalSize = 1;
        this._highlightLayer2.blurHorizontalSize = 1;
        this._highlightLayer2.outerGlow = true;
        this._highlightLayer2.innerGlow = true;

        //var sphere = Mesh.CreateBox("sphere1", 2, scene);
        //sphere.position.x += 1.1;
        //sphere.position.y = 1;
        //sphere.material = Materials.List[BlockColor.Green];
        
        //this._highlightLayer.addMesh(sphere, Color3.Green());
    }

    public HighlightMesh(mesh: Mesh, flag: boolean = false) {
        var layer = mesh.name.startsWith("GameBlock_") ? this._highlightLayer1 : this._highlightLayer2;

        if (flag) {
            layer.addMesh(mesh, Color3.Green());
        }
        else
            layer.removeMesh(mesh);
    }

    // 裁剪区域(左上点，右下点)
    private _span = 10;
    private _cornerSpan = 60;
    private _lineW = 10;

    private _cropLeft: number = 0;
    private _cropTop: number = 0;
    private _cropRight: number = 0;
    private _cropBottom: number = 0;
    private _cropPts: Array<Vector2> = [Vector2.Zero(), Vector2.Zero()];

    private _lines: Array<Line> = [];
    private _ellipses: Array<Ellipse> = [];
    private _crops: Array<UIImgae> = [];
    private _demoBtn: Nullable<Button> = null;

    // 坐标轴名称
    private _textBlocks: Array<TextBlock> = [];
    private _textEllipses: Array<Ellipse> = [];

    private changeCropState() {
        this._lines.forEach(line => {
            line.isVisible = this._cropState;
        });

        this._ellipses.forEach(line => {
            line.isVisible = this._cropState;
        });

        if (this._demoBtn)
            this._demoBtn.isVisible = !this._cropState;

        this._crops.forEach(image => {
            image.isVisible = this._cropState;
        });

        this._groundGrid.setEnabled(!this._cropState);
    }

    public ClickCrop(): boolean {
        // for debug
        if (this._test.length == 3
            && this._test[0] == 1
            && this._test[1] == 2
            && this._test[2] == 3) {
            this.LoadVoxFile();
            return false;
        }

        if (this.Error == EditorError.Ok) {

            this.calCropRect();

            this.CropState = true;

            this.reizeUI();
        }

        return (this.Error == EditorError.Ok);
    }

    private calCropRect() {
        // 计算一个正方形
        var w = Editor.Engine.getRenderWidth();
        var h = Editor.Engine.getRenderHeight();

        var span = this._cornerSpan / 2;

        // pc 宽屏模式(先确定高)
        if (w > h) {
            this._cropLeft = (w - (h - 3 * span)) / 2;
            this._cropTop = span;
            this._cropRight = this._cropLeft;
            this._cropBottom = 2 * span;

        } else { // 手机窄屏模式(先确定宽)

            this._cropLeft = span;
            this._cropTop = (h - (w - 2 * span)) / 2;;
            this._cropRight = span;
            this._cropBottom = this._cropTop;
        }
        // this._cropLeft = span;
        // this._cropTop = span;
        // this._cropRight = span;
        // this._cropBottom = 2 * span;
    }

    private createLine(x1: number, y1: number, x2: number, y2: number, width: number = 1, color: string, dash: Array<number> = []) {

        var line = new Line("line");
        line.lineWidth = width; //* window.devicePixelRatio;
        line.color = color;
        //lien.moveToVector3(new Vector3(0, 0, 0), Editor.Scene);
        line.x1 = x1;
        line.y1 = y1;
        line.x2 = x2;
        line.y2 = y2;
        line.dash = dash;
        line.isVisible = this._cropState;

        line.isPointerBlocker = true;
        line.isHitTestVisible = true;

        return line;
    }

    private createEllipse(index: number, x: number, y: number) {
        var ellipse = new Ellipse("coner:" + index);
        ellipse.metadata = index;

        ellipse.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        ellipse.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        ellipse.left = x - this._cornerSpan / 2;
        ellipse.top = y - this._cornerSpan / 2;
        ellipse.thickness = 4;
        ellipse.width = this._cornerSpan + "px";
        ellipse.height = this._cornerSpan + "px";
        ellipse.color = "#ff0000"
        ellipse.alpha = 0.0;
        ellipse.background = "green";

        ellipse.isVisible = this._cropState;
        ellipse.isPointerBlocker = true;
        ellipse.isHitTestVisible = true;

        ellipse.onPointerDownObservable.add((coordinates: Vector2WithInfo, es: EventState) => {
            //console.log("onPointerDownObservable:" + coordinates.x + "", + coordinates.y);
            var target = es.target as Ellipse;
            //console.log(coordinates.x + ","  + coordinates.y + "," + target.metadata);
            this.startDrag(target.metadata, coordinates.x, coordinates.y);
        });

        ellipse.onPointerUpObservable.add((coordinates: Vector2WithInfo, es: EventState) => {
            //console.log("onPointerUpObservable:" + coordinates.x + "", + coordinates.y);
            var target = es.target as Ellipse;
            this.drop(target.metadata, coordinates.x, coordinates.y)
        });

        ellipse.onPointerMoveObservable.add((coordinates: Vector2, es: EventState) => {
            //console.log("onPointerMoveObservable:" + coordinates.x + "", + coordinates.y);
            var target = es.target as Ellipse;
            this.drag(target.metadata, coordinates.x, coordinates.y)
        });

        //ellipse.onPointerClickObservable

        return ellipse;
    }

    private _moveIndex = -1;
    private _startingPoint: Nullable<Vector2> = null;
    private _isDrag = false;
    private _isDrop = false;
    private _itemStart: Nullable<Vector2> = null;

    private startDrag(index: number, x: number, y: number): void {
        var ellipse = this._ellipses[index];
        ellipse.alpha = 0.3;

        this._moveIndex = index;
        var w = Editor.Engine.getRenderWidth();
        var h = Editor.Engine.getRenderHeight();

        this._startingPoint = new Vector2(x, y);
        if (index == 0)
            this._itemStart = new Vector2(this._cropLeft, this._cropTop);
        else if (index == 1)
            this._itemStart = new Vector2(this._cropLeft, this._cropBottom);
        else if (index == 2)
            this._itemStart = new Vector2(this._cropRight, this._cropBottom);
        else if (index == 3)
            this._itemStart = new Vector2(this._cropRight, this._cropTop);

        this._isDrag = true;
        this._isDrop = false;

        //console.log("drag:" + x + "," + y + "," + ellipse.left + "," + ellipse.top);
    }

    private drag(index: number, x: number, y: number): void {
        var ellipse = this._ellipses[index];

        this.move(index, x, y);
    }

    private drop(index: number, x: number, y: number): void {
        if (this._moveIndex >= 0) {
            var ellipse = this._ellipses[this._moveIndex];
            ellipse.alpha = 0.0;
        }

        //this.move(index, x, y);

        this._moveIndex = -1;
        this._isDrag = false;
        this._isDrop = true;
        this._startingPoint = null;
        //console.log("drop");
    }

    private move(index: number, x: number, y: number) {
        if (!this._startingPoint) return;
        if (this._isDrag == true && this._isDrop == false) {
            var w = Editor.Engine.getRenderWidth();
            var h = Editor.Engine.getRenderHeight();

            let diff = this._startingPoint.subtract(new Vector2(x, y));

            // 范围检查
            if (index == 0) {
                this._cropLeft = Math.max(this._cornerSpan / 2, -diff.x + this._itemStart!.x);
                this._cropTop = Math.max(this._cornerSpan / 2, -diff.y + this._itemStart!.y);

                this._cropLeft = Math.min(this._cropLeft, w - this._cropRight - this._cornerSpan * 3);
                this._cropTop = Math.min(this._cropTop, h - this._cropBottom - this._cornerSpan * 2);
            }
            else if (index == 1) {
                this._cropLeft = Math.max(this._cornerSpan / 2, -diff.x + this._itemStart!.x);
                this._cropBottom = (diff.y + this._itemStart!.y);

                this._cropLeft = Math.min(this._cropLeft, w - this._cropRight - this._cornerSpan * 3);

                if (this._cropBottom > (h - (this._cropTop + this._cornerSpan * 2)))
                    this._cropBottom = h - this._cropTop - this._cornerSpan * 2;
                if (this._cropBottom < this._cornerSpan / 2)
                    this._cropBottom = this._cornerSpan / 2;
            }
            else if (index == 2) {
                this._cropRight = (diff.x + this._itemStart!.x);
                this._cropBottom = (diff.y + this._itemStart!.y);

                if (this._cropRight > (w - (this._cropLeft + this._cornerSpan * 3)))
                    this._cropRight = w - this._cropLeft - this._cornerSpan * 3;
                if (this._cropRight < this._cornerSpan / 2)
                    this._cropRight = this._cornerSpan / 2;

                if (this._cropBottom > (h - (this._cropTop + this._cornerSpan * 2)))
                    this._cropBottom = h - this._cropTop - this._cornerSpan * 2;
                if (this._cropBottom < this._cornerSpan / 2)
                    this._cropBottom = this._cornerSpan / 2;
            }
            else if (index == 3) {
                this._cropRight = (diff.x + this._itemStart!.x);
                this._cropTop = Math.max(this._cornerSpan / 2, -diff.y + this._itemStart!.y);

                this._cropTop = Math.min(this._cropTop, h - this._cropBottom - this._cornerSpan * 2);

                if (this._cropRight > (w - (this._cropLeft + this._cornerSpan * 3)))
                    this._cropRight = w - this._cropLeft - this._cornerSpan * 3;
                if (this._cropRight < this._cornerSpan / 2)
                    this._cropRight = this._cornerSpan / 2;
            }

            this.reizeUI();
        }
    }

    private reizeUI() {

        var w = Editor.Engine.getRenderWidth();
        var h = Editor.Engine.getRenderHeight();

        this._cropPts[0] = new Vector2(this._cropLeft, this._cropTop);
        this._cropPts[1] = new Vector2(w - this._cropRight, h - this._cropBottom);

        var index = 0;
        // 选择框 左 下 右 上        
        this._lines[index].x1 = this._cropPts[0].x;
        this._lines[index].y1 = this._cropPts[0].y;
        this._lines[index].x2 = this._cropPts[0].x;
        this._lines[index].y2 = this._cropPts[1].y;
        index++;

        this._lines[index].x1 = this._cropPts[0].x;
        this._lines[index].y1 = this._cropPts[1].y;
        this._lines[index].x2 = this._cropPts[1].x;
        this._lines[index].y2 = this._cropPts[1].y;
        index++;

        this._lines[index].x1 = this._cropPts[1].x;
        this._lines[index].y1 = this._cropPts[1].y;
        this._lines[index].x2 = this._cropPts[1].x;
        this._lines[index].y2 = this._cropPts[0].y;
        index++;

        this._lines[index].x1 = this._cropPts[1].x;
        this._lines[index].y1 = this._cropPts[0].y;
        this._lines[index].x2 = this._cropPts[0].x;
        this._lines[index].y2 = this._cropPts[0].y;
        index++;

        // corner
        var pt = new Vector2(this._cropPts[0].x - this._lineW, this._cropPts[0].y - this._lineW);

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x + this._cornerSpan;
        this._lines[index].y2 = pt.y;
        index++;

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x;
        this._lines[index].y2 = pt.y + this._cornerSpan;
        index++;

        // 左下
        pt = new Vector2(this._cropPts[0].x - this._lineW, this._cropPts[1].y + this._lineW);

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x;
        this._lines[index].y2 = pt.y - this._cornerSpan;
        index++;

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x + this._cornerSpan;
        this._lines[index].y2 = pt.y;
        index++;

        // 右下
        pt = new Vector2(this._cropPts[1].x + this._lineW, this._cropPts[1].y + this._lineW);

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x - this._cornerSpan;
        this._lines[index].y2 = pt.y;
        index++;

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x;
        this._lines[index].y2 = pt.y - this._cornerSpan;
        index++;

        // 右上
        pt = new Vector2(this._cropPts[1].x + this._lineW, this._cropPts[0].y - this._lineW);

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x - this._cornerSpan;
        this._lines[index].y2 = pt.y;
        index++;

        this._lines[index].x1 = pt.x;
        this._lines[index].y1 = pt.y;
        this._lines[index].x2 = pt.x;
        this._lines[index].y2 = pt.y + this._cornerSpan;
        index++;

        index = 0;
        // 左上
        this._ellipses[index].left = this._cropPts[0].x - this._cornerSpan / 2;
        this._ellipses[index].top = this._cropPts[0].y - this._cornerSpan / 2;
        index++;

        // 左下
        this._ellipses[index].left = this._cropPts[0].x - this._cornerSpan / 2;
        this._ellipses[index].top = this._cropPts[1].y - this._cornerSpan / 2;
        index++;

        // 右下
        this._ellipses[index].left = this._cropPts[1].x - this._cornerSpan / 2;
        this._ellipses[index].top = this._cropPts[1].y - this._cornerSpan / 2;
        index++;

        // 右上
        this._ellipses[index].left = this._cropPts[1].x - this._cornerSpan / 2;
        this._ellipses[index].top = this._cropPts[0].y - this._cornerSpan / 2;
        index++;

        this._crops[0].left = (this._cropPts[0].x + this._cropPts[1].x) / 2 - this._cornerSpan - this._cornerSpan / 2;
        this._crops[0].top = this._cropPts[1].y - this._cornerSpan - 10;

        this._crops[1].left = (this._cropPts[0].x + this._cropPts[1].x) / 2 + this._cornerSpan - this._cornerSpan / 2;
        this._crops[1].top = this._cropPts[1].y - this._cornerSpan - 10;
    }

    private createCropShapes(advancedTexture: AdvancedDynamicTexture) {
        var w = Editor.Engine.getRenderWidth();
        var h = Editor.Engine.getRenderHeight();

        this._cropPts[0] = new Vector2(this._cropLeft, this._cropTop);
        this._cropPts[1] = new Vector2(w - this._cropRight, h - this._cropBottom);

        var lineW = 2;
        var lineColor = "#9a9a9a";
        var dash = [20, 10];
        // 选择框 左 下 右 上
        var line = this.createLine(this._cropPts[0].x, this._cropPts[0].y, this._cropPts[0].x, this._cropPts[1].y, lineW, lineColor, dash);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(this._cropPts[0].x, this._cropPts[1].y, this._cropPts[1].x, this._cropPts[1].y, lineW, lineColor, dash);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(this._cropPts[1].x, this._cropPts[1].y, this._cropPts[1].x, this._cropPts[0].y, lineW, lineColor, dash);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(this._cropPts[1].x, this._cropPts[0].y, this._cropPts[0].x, this._cropPts[0].y, lineW, lineColor, dash);
        advancedTexture.addControl(line);
        this._lines.push(line);

        lineColor = "#4CAF50";//"#ffffff";
        // 左上coner
        var pt = new Vector2(this._cropPts[0].x - this._lineW, this._cropPts[0].y - this._lineW);
        line = this.createLine(pt.x, pt.y, pt.x + this._cornerSpan, pt.y, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(pt.x, pt.y, pt.x, pt.y + this._cornerSpan, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        // 左下
        pt = new Vector2(this._cropPts[0].x - this._lineW, this._cropPts[1].y + this._lineW);
        line = this.createLine(pt.x, pt.y, pt.x + this._cornerSpan, pt.y, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(pt.x, pt.y, pt.x, pt.y - this._cornerSpan, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        // 右下
        pt = new Vector2(this._cropPts[1].x + this._lineW, this._cropPts[1].y + this._lineW);
        line = this.createLine(pt.x, pt.y, pt.x - this._cornerSpan, pt.y, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(pt.x, pt.y, pt.x, pt.y - this._cornerSpan, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        // 右上
        pt = new Vector2(this._cropPts[1].x + this._lineW, this._cropPts[0].y - this._lineW);
        line = this.createLine(pt.x, pt.y, pt.x - this._cornerSpan, pt.y, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        line = this.createLine(pt.x, pt.y, pt.x, pt.y + this._cornerSpan, this._lineW, lineColor);
        advancedTexture.addControl(line);
        this._lines.push(line);

        // 左上
        var ellipse = this.createEllipse(0, this._cropPts[0].x, this._cropPts[0].y);
        advancedTexture.addControl(ellipse);
        this._ellipses.push(ellipse);

        // 左下
        ellipse = this.createEllipse(1, this._cropPts[0].x, this._cropPts[1].y);
        advancedTexture.addControl(ellipse);
        this._ellipses.push(ellipse);

        // 右下
        ellipse = this.createEllipse(2, this._cropPts[1].x, this._cropPts[1].y);
        advancedTexture.addControl(ellipse);
        this._ellipses.push(ellipse);

        // 右上
        ellipse = this.createEllipse(3, this._cropPts[1].x, this._cropPts[0].y);
        advancedTexture.addControl(ellipse);
        this._ellipses.push(ellipse);

        // cancel(点击点附近)
        var image = new UIImgae("cancel", cancelUrl);
        image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        image.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        image.left = (this._cropPts[0].x + this._cropPts[1].x) / 2 - this._cornerSpan - this._cornerSpan / 2;
        image.top = this._cropPts[1].y - this._cornerSpan - 10;
        image.width = this._cornerSpan + "px";
        image.height = this._cornerSpan + "px";
        image.isVisible = this._cropState;
        image.isPointerBlocker = true;
        image.isHitTestVisible = true;

        //image.autoScale
        advancedTexture.addControl(image);
        image.onPointerUpObservable.add(() => {
            console.log("放弃截图");
            this.CropState = false;

            if (this.onScreenShot)
                this.onScreenShot(this._id);
        });
        this._crops.push(image);

        // cut (点击点附近)
        image = new UIImgae("cut", okUrl);
        image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        image.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        image.left = (this._cropPts[0].x + this._cropPts[1].x) / 2 + this._cornerSpan - this._cornerSpan / 2;
        image.top = this._cropPts[1].y - this._cornerSpan - 10;
        image.width = this._cornerSpan + "px";
        image.height = this._cornerSpan + "px";
        image.isVisible = this._cropState;
        image.isPointerBlocker = true;
        image.isHitTestVisible = true;

        //image.autoScale
        advancedTexture.addControl(image);
        image.onPointerUpObservable.add(() => {
            console.log("截图");
            this.Screenshot((id, name, data) => {
                this.CropState = false;

                if (this.onScreenShot)
                    this.onScreenShot(id, name, data);
            }, true);
        });
        this._crops.push(image);
    }

    private createGizmoUI(advancedTexture: AdvancedDynamicTexture){
        // GUI
        //var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("AxtUI", true, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        //advancedTexture.idealWidth = 600;
        //console.log(advancedTexture.isRenderTarget);

        var xyz = ["x", "y", "z"]
        var colors = [Color3.Red(), Color3.Green(), Color3.Blue()];
        var meshes = [
            this._gizmoManager.gizmos.positionGizmo?.xGizmo._rootMesh,
            this._gizmoManager.gizmos.positionGizmo?.yGizmo._rootMesh,
            this._gizmoManager.gizmos.positionGizmo?.zGizmo._rootMesh
        ];

        for (var i = 0; i < 3; i++) {
            var label = new TextBlock();
            label.text = xyz[i];
            label.color =  "#ffffff";//invert('#282b35');           // —> #d7d4ca";

            var target = new Ellipse();
            target.width = "20px";
            target.height = "20px";
            //target.color = Color3.Red().scale(0.5).toHexString();
            target.thickness = 0;
            target.background = colors[i].scale(0.5).toHexString();
            target.addControl(label);
            advancedTexture.addControl(target);
            target.linkOffsetY = -20;
            target.isVisible = false;

            this._textEllipses.push(target);

            // ToDO
            var mesh = meshes[i];
            if (mesh)
                target.linkWithMesh(mesh.getChildMeshes()[0].getChildMeshes()[0]);
        }
    }

    private createUI() {
        var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", true, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);

        this.createCropShapes(advancedTexture);

        this.createGizmoUI(advancedTexture);

        //var groundMaterial = this._ground.material as StandardMaterial;
        //var groundMaterial = Editor.Skybox.material as StandardMaterial;

        /*
        var picker = new ColorPicker();
        //picker.
        picker.value = groundMaterial.diffuseColor;
        picker.height = "150px";
        picker.width = "150px";
        picker.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        picker.onValueChangedObservable.add(function (value) { // value is a color3
            groundMaterial.diffuseColor.copyFrom(value);
            //groundMaterial.ambientColor.copyFrom(value);
            //groundMaterial.emissiveColor.copyFrom(value);
            //groundMaterial.specularColor.copyFrom(value);
        });

        advancedTexture.addControl(picker);
        */

        DefaultLoadingScreen.DefaultLogoUrl = cubeUrl;

        Editor.Engine.displayLoadingUI();

        Editor.Scene.executeWhenReady(function () {
            Editor.Engine.hideLoadingUI();
        })
    }

    private getScreenName(): string {
        var name = this._id; // 图纸id

        name += "_";
        name += Math.round(Tools.ToDegrees(Editor.Camera.alpha));
        name += "_";
        name += Math.round(Tools.ToDegrees(Editor.Camera.beta));
        name += "_";
        name += Math.round(Editor.Camera.radius);
        name += "_";
        name += this._skyColor.substr(1, 6); // #ffffff
        name += "_";
        name += this._groundColor.substr(1, 6); // #ffffff

        return name;
    }

    public Screenshot(successCallback?: (id: string, name: string, data: string) => void
        , interactive: boolean = false) {

        this._coordinateOrign.setEnabled(false);
        this._groundGrid.setEnabled(false);

        // 按钮隐藏
        this._crops.forEach(image => {
            image.isVisible = false;
        });

        // 当用户收到不参与截图时，即保存时静默截图
        if (interactive === false)
            this.calCropRect();

        if (!Editor._ScreenshotCanvas) {
            Editor._ScreenshotCanvas = document.createElement("canvas");
        }

        // 记录截图前活动相机
        var camera = Editor.Camera;
        var scene = camera.getScene();
        var previousCamera: Nullable<Camera> = null;
        var previousCameras = scene.activeCameras;

        scene.activeCameras = null;

        if (scene.activeCamera !== camera) {
            previousCamera = scene.activeCamera;
            scene.activeCamera = camera;
        }

        Editor.Scene.render();
        Editor.Engine.onEndFrameObservable.addOnce(() => {
            // 创建一个canvas
            let data = Editor.Canvas.toDataURL();
            let temp_img = new Image();
            let lineW = 2;
            let w = Editor.Engine.getRenderWidth() - this._cropLeft - this._cropRight - 2 * lineW;
            let h = Editor.Engine.getRenderHeight() - this._cropTop - this._cropBottom - 2 * lineW;

            //let x = 30;//Math.ceil((this.sheet.x + 10) * Laya.stage.clientScaleX);
            //let y = 30;//Math.ceil((this.sheet.y + 60) * Laya.stage.clientScaleY);

            temp_img.style.width = w + 'px';
            temp_img.style.height = h + 'px';

            temp_img.src = data;
            Editor._ScreenshotCanvas.width = w;
            Editor._ScreenshotCanvas.height = h;
            let ctx = Editor._ScreenshotCanvas.getContext('2d');
            temp_img.onload = () => {
                // 取得一个区域
                ctx!.drawImage(temp_img, this._cropLeft + lineW, this._cropTop + lineW, w, h, 0, 0, w, h);
                // this.createShareImg()
                var data = Editor._ScreenshotCanvas.toDataURL();

                // 传送服务器测试
                if (false) {
                    var xmlHttp = new XMLHttpRequest();
                    xmlHttp.onreadystatechange = () => {
                        if (xmlHttp.readyState == 4) {
                            if (xmlHttp.status == 200) {
                                var snippet = JSON.parse(xmlHttp.responseText);
                                var url = snippet.id;
                            } else {
                                alert("Unable to save your animations");
                            }
                        }
                    };

                    xmlHttp.open("POST", "https://localhost:5001/ScreenRecord", true);
                    xmlHttp.setRequestHeader("Content-Type", "application/json");

                    var dataToSend = {
                        data: data,
                        name: this.getScreenName(), // 名字(包含旋转角度，半径，颜色)
                    };

                    xmlHttp.send(JSON.stringify(dataToSend));
                }

                Tools._ScreenshotCanvas = Editor._ScreenshotCanvas;

                if (successCallback) {
                    successCallback(this._id, this.getScreenName(), data);
                }
                this._groundGrid.setEnabled(true);

                // 存储本地
                if (false) {
                    var fileName = this.getScreenName();
                    Tools.ToBlob(Editor._ScreenshotCanvas, (blob) => {
                        //Creating a link if the browser have the download attribute on the a tag, to automatically start download generated image.
                        if (("download" in document.createElement("a"))) {
                            if (!fileName) {
                                var date = new Date();
                                var stringDate = (date.getFullYear() + "-" + (date.getMonth() + 1)).slice(2) + "-" + date.getDate() + "_" + date.getHours() + "-" + ('0' + date.getMinutes()).slice(-2);
                                fileName = "screenshot_" + stringDate + ".png";
                            }
                            Tools.Download(blob!, fileName);
                        }
                        else {
                            var url = URL.createObjectURL(blob);

                            var newWindow = window.open("");
                            if (!newWindow) { return; }
                            var img = newWindow.document.createElement("img");
                            img.onload = function () {
                                // no longer need to read the blob so it's revoked
                                URL.revokeObjectURL(url);
                            };
                            img.src = url;
                            newWindow.document.body.appendChild(img);
                        }
                    });
                }
            }

            this.updateAxis();

            // 截图后还原活动相机
            if (previousCamera) {
                scene.activeCamera = previousCamera;
            }
            scene.activeCameras = previousCameras;
            camera.getProjectionMatrix(true); // Force cache refresh;
        });
    }

    private encodeScreenshotCanvasData(): void {

    }

    private setBeyondBorders(mesh: Mesh, flag: boolean = false) {
        //mesh.renderOutline = flag;

        // if (mesh.renderOverlay)
        //     mesh.renderOverlay = false;
        // if (mesh.renderOutline)
        //     mesh.renderOutline = false;

        //mesh.visibility = flag ? 0.5 : 1;

        if (flag) {
            mesh.material = Materials.List[BlockColor.Error];
            GameObject.PlayErrorAnimation(mesh);
        }
        else {
            GameObject.StopErrorAnimation(mesh);
            mesh.material = Materials.List[mesh.metadata];
        }
    }

    private setIntersects(mesh: Mesh, flag: boolean = false) {
        //mesh.renderOverlay = flag;

        if (flag) {
            mesh.material = Materials.List[BlockColor.Error];
            GameObject.PlayErrorAnimation(mesh);
        }
        else {
            GameObject.StopErrorAnimation(mesh);
            mesh.material = Materials.List[mesh.metadata];
        }
    }

    // 新加或者删除时调用计算个数
    private calBlockNum() {
        //预览模式什么也不做
        if (this._previewFlag)
            return;

        this._blockNums = [0, 0, 0, 0, 0, 0, 0, 0];
        this._modelNums.clear();

        // TODO 按照实际计算个数
        GameBlock.LiveInstances().forEach(item => {
            item.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                this._blockNums[c - 1]++;
            })
        });

        // Model 计算一次缓存即可
        GameModel.LiveInstances().forEach((model) => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                this._blockNums[c - 1]++;
            })

            var id = model.Data.ID
            if(this._modelNums.has(id))
                this._modelNums.set(id, this._modelNums.get(id)! + 1);
            else
                this._modelNums.set(id, 1);
        });

        if (this.onBlockChange)
            this.onBlockChange(this._blockNums);

        if(this.onModelChange){
            this.onModelChange(this._modelNums);
        }
    }

    // 加载，增加，删除，移动都会触发检测碰撞出界状态
    private checkGameObject(/*gameObject: Nullable<GameObject>*/): boolean {
        // 预览模式什么也不做
        if (this._previewFlag)
            return true;

        // 取消碰撞/重叠
        this._error = EditorError.Ok;

        // (三个状态)正常/越界/重叠
        //mesh.visibility = 0.8;
        //mesh.renderOutline = true;
        //mesh.renderOverlay = true;

        var start = PrecisionDate.Now;

        // 重置 251 * 251 * 251 = 15813251
        for (var i = 0; i < Editor.MaxRange * Editor.MaxRange * Editor.MaxRange; i++) {
            if (this._blocks[i].size > 0)
                this._blocks[i].clear();
        }

        var time1 = PrecisionDate.Now;

        var that = this;
        GameBlock.LiveInstances().forEach((block) => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                this.setIntersects(mesh);
                this.setBeyondBorders(mesh);

                var index = that.cubeIndex(x, y, z);
                if (index >= 0 && index < that._blocks.length)
                    that._blocks[index].add(block);
                else {
                    //this._error |= EditorError.BeyondBorders;
                    console.error(index);
                }
            });
        });

        GameModel.LiveInstances().forEach((model) => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                this.setIntersects(mesh);
                this.setBeyondBorders(mesh);

                var index = that.cubeIndex(x, y, z);
                if (index >= 0 && index < that._blocks.length)
                    that._blocks[index].add(model);
                else {
                    //this._error |= EditorError.BeyondBorders;
                    console.error(index);
                }
            });
        });

        var time2 = PrecisionDate.Now;

        // 是否出界
        GameBlock.LiveInstances().forEach((block) => {

            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                //console.log(x + " " + this._rangeHalf);

                if (x > this._rangeHalf
                    || x < -this._rangeHalf
                    || z > this._rangeHalf
                    || z < -this._rangeHalf
                    || y < 0
                    || y >= this._range) {
                    this._error |= EditorError.BeyondBorders;

                    this.setBeyondBorders(mesh, true);
                }
                else
                    this.setBeyondBorders(mesh);
            });
        });

        GameModel.LiveInstances().forEach((model) => {
            var flag = false;
            if (model.Mesh.position.x > this._rangeHalf
                || model.Mesh.position.x < -this._rangeHalf
                || model.Mesh.position.z > this._rangeHalf
                || model.Mesh.position.z < -this._rangeHalf
                || model.Mesh.position.y < 0
                || model.Mesh.position.y >= this._range) {
                this._error |= EditorError.BeyondBorders;

                flag = true;
            }

            //model.Mesh.position

            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {

                //console.log(x + " " + this._rangeHalf);
                this.setBeyondBorders(mesh, flag);
            });
        });

        var time3 = PrecisionDate.Now;

        // 是否碰撞
        var list = new Set<GameObject>();

        var num = this._range * this._range * this._range;
        for (var i = 0; i < num; i++) {
            if (this._blocks[i].size > 1) {

                this._blocks[i].forEach((item) => {
                    list.add(item);
                });
            }
        }

        if (list.size > 0) {
            this._error |= EditorError.Intersects;

            list.forEach((item) => {
                item.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                    //mesh.visibility = 0.8;
                    this.setIntersects(mesh, true);
                });
            });
        }

        var time4 = PrecisionDate.Now;

        // 如果没有错误则保存
        if (this._error == EditorError.Ok) {
            this.saveCached();
        }

        var time5 = PrecisionDate.Now;

        console.log(`t1:${(time1 - start).toFixed()} t2:${(time2 - time1).toFixed()} t3:${(time3 - time2).toFixed()} t4:${(time4 - time3).toFixed()} t5:${(time5 - time4).toFixed()}`);

        return (this._error != EditorError.Ok);
    }

    public LoadVoxFile() {
        var data = prompt("Input Vox", "1,1,4,B1CD");
        if (!data) {
            return;
        }

        // 创建一个模型(取得模型数据)
        var modelData = new ModelData("TempVox");
        modelData.Deserialize(data);

        modelData.Blocks.forEach((block: Vector4, index: number) => {
            new GameBlock(new Vector3(block.x, block.y, block.z), block.w, true, true, false);
        });

        // 全部选中
        Editor.Instance.SelectAll();

        this.checkGameObject();
    }

    public Test() {
        // var num = this.Range * this.Range * this.Range;
        // num = 1000;
        // for (var i = 0; i < num; i++) {
        //     var x = this.getRandomInt(-25, 25);
        //     var z = this.getRandomInt(-25, 25);
        //     var y = this.getRandomInt(0, 50);

        //     var pos = new Vector3(x, y, z);
        //     new GameBlock(pos, 1, true, false);
        // }

        // Test MergeMeshes Materials
        var mat1 = new StandardMaterial('mat1', Editor.Scene);
        mat1.diffuseColor = new Color3(1, 0, 0);
        
        var mat2 = new StandardMaterial('mat2', Editor.Scene);
        mat2.diffuseColor = new Color3(0, 1, 0);


        var sphere = MeshBuilder.CreateBox("cube1", {size: 2, height: 2}, Editor.Scene);
        sphere.material = Materials.List[BlockColor.Green]; //mat1;
        sphere.position.y = 1;

        var cube = MeshBuilder.CreateBox("cube2", { size: 1, height: 3 }, Editor.Scene);
        cube.position = new Vector3(1, 1.5, 0);
        cube.material = Materials.List[BlockColor.Red]; //mat2;

        //parameters - arrayOfMeshes, disposeSource, allow32BitsIndices, meshSubclass, subdivideWithSubMeshes, multiMultiMaterial
        var mesh = Mesh.MergeMeshes([sphere, cube], true, true, undefined, false, true);
            


        this.checkGameObject();
    }

    public SelectAll(): void {
        //this._selectedList.add(object);
        //this.updateAxis();

        var that = this;
        GameBlock.LiveInstances().forEach((block) => {
            block.Selected = true;
            that._selectedList.add(block);

        });

        GameModel.LiveInstances().forEach((model) => {
            model.Selected = true;
            that._selectedList.add(model);
        });

        this.updateAxis();

        this._gizmoManager.attachToMesh(null);
        this._textEllipses.forEach(item => {
            item.isVisible = false;
        });
    }

    public UnSelectAll() {
        this._selectedList.forEach((item) => {
            if (item.Selected)
                item.Selected = false;
        });

        this._selectedList.clear();
        this._selectedObject = null;
        this.updateAxis();

        this._gizmoManager.attachToMesh(null);
        this._textEllipses.forEach(item => {
            item.isVisible = false;
        });
    }

    // (UI界面操作)倒退
    public Undo() {
        if (this._executeCursor == 0) {
            console.error("no undo");
            return;
        }

        // TODO 历史操作列表，当前操作索引，倒退多少步，操作之后就要跳转到最新索引游标
        //var historyList = [];
        //var executeCursor = 0;
        //executeCursor++;
        //executeCursor--;
        var execute = this._historyList[this._executeCursor - 1];
        if (execute.type == ExecuteEnum.AddGameBlock) {
            var gameBlock = GameBlock.FindByPositionData(execute.position, execute.data);
            if (gameBlock) {
                this.unSelectGameObject(gameBlock);
                gameBlock.Dispose();
            }
            //this._historyList.push({ type: ExecuteType.AddGameBlock, position: coordinate, data: this._color });
        }
        else if (execute.type == ExecuteEnum.AddGameModel) {
            var gameModel = GameModel.FindByPositionData(execute.position, execute.data);
            if (gameModel) {
                this.unSelectGameObject(gameModel);
                gameModel.Dispose();
            }
            //this._historyList.push({ type: ExecuteType.AddGameModel, position: gameModel.Pos, data: gameModel.Data });
        }
        else if (execute.type == ExecuteEnum.Delete) {
            // items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: item.Color/item.Data });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Block) {
                    new GameBlock(item.position, item.data);
                }
                if (item.type == ModelEnum.Model) {
                    new GameModel(item.position, item.data);
                }
            })
        }
        else if (execute.type == ExecuteEnum.Move || execute.type == ExecuteEnum.AutoCenter) {
            //items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: { axis: axis, forword: forword, data:Color/Data } });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Block) {
                    var gameBlock = GameBlock.FindByPositionData(item.position, item.data.data);
                    if (gameBlock != null)
                        gameBlock.Move(item.data.axis, -item.data.forword);
                }
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.position, item.data.data);
                    if (gameModel)
                        gameModel.Move(item.data.axis, -item.data.forword);
                }
            })
        }
        else if (execute.type == ExecuteEnum.Rotate) {
            //items.push({ type: ModelEnum.Model, position: item.Pos.clone(), data: { axis: axis, forword: forword, data:Data } });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.position, item.data.data);
                    if (gameModel)
                        gameModel.Rotate(item.data.axis, -item.data.forword);
                }
            })
        }
        else if (execute.type == ExecuteEnum.SkyColor) {
            //this._historyList.push({ type: ExecuteType.SkyColor, data: { old: this._skyColor, new: value } });
            //this.SkyColor = execute.data.old;

            this.setSkyColor(execute.data.old);
        }
        else if (execute.type == ExecuteEnum.GroundColor) {
            //this._historyList.push({ type: ExecuteType.GroundColor, data: { old: this._skyColor, new: value } });
            //this.GroundColor = execute.data.old;

            this.setSkyColor(execute.data.old);
        }

        this._executeCursor--;
        this.updateHistory();

        console.log(`${execute.type}-${this._executeCursor}-${this._historyList.length}-undo`);
    }

    // (UI界面操作)前进
    public Redo() {
        if (this._executeCursor == this._historyList.length){
            console.error("no redo");
            return;
        }

        var execute = this._historyList[this._executeCursor];
        if (execute.type == ExecuteEnum.AddGameBlock) {
            new GameBlock(execute.position, execute.data);
            //this._historyList.push({ type: ExecuteType.AddGameBlock, position: coordinate, data: this._color });
        }
        else if (execute.type == ExecuteEnum.AddGameModel) {
            new GameModel(execute.position, execute.data);
            //this._historyList.push({ type: ExecuteType.AddGameModel, position: gameModel.Pos, data: gameModel.Data });
        }
        else if (execute.type == ExecuteEnum.Delete) {
            // items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: item.Color/Data });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Block) {
                    var gameBlock = GameBlock.FindByPositionData(item.position, item.data);
                    if (gameBlock) {
                        this.unSelectGameObject(gameBlock);
                        gameBlock.Dispose();
                    }
                }
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.position, item.data);
                    if (gameModel) {
                        this.unSelectGameObject(gameModel);
                        gameModel.Dispose();
                    }
                }
            })
        }
        else if (execute.type == ExecuteEnum.Move) {
            //items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: { axis: axis, forword: forword, data:Color/Data, from:fromPos } });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Block) {
                    var gameBlock = GameBlock.FindByPositionData(item.data.from, item.data.data);
                    if (gameBlock)
                        gameBlock.Move(item.data.axis, item.data.forword);
                }
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.data.from, item.data.data);
                    if (gameModel)
                        gameModel.Move(item.data.axis, item.data.forword);
                }
            })
        }
        else if (execute.type == ExecuteEnum.Rotate) {
            //items.push({ type: ModelEnum.Model, position: item.Pos.clone(), data: { axis: axis, forword: forword, data:Data } });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.position, item.data.data);
                    if (gameModel)
                        gameModel.Rotate(item.data.axis, item.data.forword);
                }
            })
        }
        else if (execute.type == ExecuteEnum.AutoCenter) {
            //items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: { axis: axis, forword: forword, data:Color/Data } });
            execute.items.forEach((item: any) => {
                if (item.type == ModelEnum.Block) {
                    var gameBlock = GameBlock.FindByPositionData(item.position, item.data.data);
                    if (gameBlock)
                        gameBlock.Move(item.data.axis, item.data.forword);
                }
                if (item.type == ModelEnum.Model) {
                    var gameModel = GameModel.FindByPositionData(item.position, item.data.data);
                    if (gameModel)
                        gameModel.Move(item.data.axis, item.data.forword);
                }
            })
        }
        else if (execute.type == ExecuteEnum.SkyColor) {
            //this._historyList.push({ type: ExecuteType.SkyColor, data: { old: this._skyColor, new: value } });
            //this.SkyColor = execute.data.new;

            this._skyColor = execute.data.new;
            (<StandardMaterial>(Editor.Skybox.material)).emissiveColor = Color3.FromHexString(this._skyColor);
        }
        else if (execute.type == ExecuteEnum.GroundColor) {
            //this._historyList.push({ type: ExecuteType.GroundColor, data: { old: this._skyColor, new: value } });
            //this.GroundColor = execute.data.new;

            this._groundColor = execute.data.new;
            (<StandardMaterial>(this._ground.material)).emissiveColor = Color3.FromHexString(this._groundColor);
        }

        this._executeCursor++;
        this.updateHistory();
 
        console.log(`${execute.type}-${this._executeCursor}-${this._historyList.length}-redo`);
   }

    // (UI界面调用)删除
    public Delete() {
        var items: Array<any> = [];

        this._selectedList.forEach(item => {
            //items
            if (item instanceof GameBlock) {
                items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: item.Color });
            }
            else if (item instanceof GameModel) {
                items.push({ type: ModelEnum.Model, position: item.Pos.clone(), data: item.Data });
            }

            this.unSelectGameObject(item);
            item.Dispose();
        })

        this._selectedList.clear();
        this.checkGameObject();
        this.calBlockNum();

        // 保持前
        this.pushHistory({ type: ExecuteEnum.Delete, items: items });
    }

    public Rotate(dir: number, forword: number = 1): boolean {
        var axis = Vector3.ZeroReadOnly;

        if (dir === 1) {
            axis = Axis.X;
        } else if (dir === 2) {
            axis = Axis.Y;
        } else if (dir === 3) {
            axis = Axis.Z;
        }

        var items: Array<any> = [];

        this._selectedList.forEach((item) => {
            //items
            if (item instanceof GameModel) {
                items.push({ type: ModelEnum.Model, position: item.Pos.clone(), data: { axis: axis, forword: forword, data: item.Data } });
            }

            item.Rotate(axis, forword);
        });

        // TODO
        // this.modifyRange();

        // 保持前
        this.pushHistory({ type: ExecuteEnum.Rotate, items: items });

        this.checkGameObject();

        return false;
    }

    public Move(dir: number, forword: number = 1): boolean {
        var axis = Vector3.ZeroReadOnly;

        if (dir === 1) {
            axis = Axis.X;
        } else if (dir === 2) {
            axis = Axis.Y;
        } else if (dir === 3) {
            axis = Axis.Z;
        }

        var items: Array<any> = [];

        this._selectedList.forEach((item) => {
            var fromPos = item.Mesh.position.clone();
            item.Move(axis, forword);

            //items
            if (item instanceof GameBlock) {
                items.push({ type: ModelEnum.Block, position: item.Pos.clone(), data: { axis: axis, forword: forword, data: item.Color, from:fromPos } });
            }
            else if (item instanceof GameModel) {
                items.push({ type: ModelEnum.Model, position: item.Pos.clone(), data: { axis: axis, forword: forword, data: item.Data, from:fromPos } });
            }
        });

        // TODO
        // this.modifyRange();

        // 保持前
        this.pushHistory({ type: ExecuteEnum.Move, items: items });

        this.checkGameObject();

        return false;
    }

    // (UI界面调用)自动居中
    public AutoCenter() {
        // x向右，z向上 俯视图
        //Editor.Camera.alpha = Tools.ToRadians(-90);
        //Editor.Camera.beta = 0;

        // x-z 平面自动平衡到0,0
        var minX = Number.MAX_VALUE;
        var maxX = -Number.MAX_VALUE;
        var minZ = Number.MAX_VALUE;
        var maxZ = -Number.MAX_VALUE;

        GameBlock.LiveInstances().forEach((block) => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                minX = Math.min(minX, x);
                minZ = Math.min(minZ, z);

                maxX = Math.max(maxX, x);
                maxZ = Math.max(maxZ, z);
            });
        });

        GameModel.LiveInstances().forEach((model) => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                minX = Math.min(minX, x);
                minZ = Math.min(minZ, z);

                maxX = Math.max(maxX, x);
                maxZ = Math.max(maxZ, z);
            });
        });

        // this._selectedList.forEach((item) => {
        //     item.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
        //         minX = Math.min(minX, x);
        //         minZ = Math.min(minZ, z);

        //         maxX = Math.max(maxX, x);
        //         maxZ = Math.max(maxZ, z);
        //     });
        // });

        var sumX = (minX + maxX);
        var spanX = Math.floor(sumX / 2);
        if (sumX % 2 != 0)
            spanX += 1;

        var sumZ = (minZ + maxZ);
        var spanZ = Math.floor(sumZ / 2);
        if (sumZ % 2 != 0)
            spanZ += 1;

        var items: Array<any> = [];

        GameBlock.LiveInstances().forEach((block) => {
            //items
            items.push({ type: ModelEnum.Block, position: block.Pos, data: { axis: Axis.X, forword: -spanX, data: block.Color } });
            block.Move(Axis.X, -spanX);

            items.push({ type: ModelEnum.Block, position: block.Pos, data: { axis: Axis.Z, forword: -spanZ, data: block.Color } });
            block.Move(Axis.Z, -spanZ);
        });

        GameModel.LiveInstances().forEach((model) => {
            // items
            items.push({ type: ModelEnum.Model, position: model.Pos.clone(), data: { axis: Axis.X, forword: -spanX, data: model.Data } });
            model.Move(Axis.X, -spanX);

            items.push({ type: ModelEnum.Model, position: model.Pos.clone(), data: { axis: Axis.Z, forword: -spanZ, data: model.Data } });
            model.Move(Axis.Z, -spanZ);
        });

        // 保持前
        this.pushHistory({ type: ExecuteEnum.AutoCenter, items: items });

        // this._selectedList.forEach((item) => {
        //     item.Move(Axis.X, -spanX);
        //     item.Move(Axis.Z, -spanZ);
        // });

        //console.log(minX + "," + maxX + "," + minZ + "," + maxZ);
    }

    // (UI界面调用)场景颜色复位
    public ResetScene() {
        this.SkyColor = "#262626";
        this.GroundColor = "#303030";
    }

    // (UI界面调用)镜头复位
    public ResetCamera() {
        if (this._is3D) {
            // Editor.Camera.orthoLeft = null;
            // Editor.Camera.orthoRight = null;
            // Editor.Camera.orthoTop = null;
            // Editor.Camera.orthoBottom = null;

            // 0.7
            // 1.1
            Editor.Camera.alpha = 0.7; //Math.PI / 4;
            Editor.Camera.beta = 1.1; //Math.PI / 4;

        } else {
            Editor.Camera.alpha = 0;
            Editor.Camera.beta = 0;

            // 如果是正交模式才调整视图区域大小
            //if (this._changePerspectiveFlag) {

            let w = Editor.Engine.getRenderWidth();
            let h = Editor.Engine.getRenderHeight();

            if (w >= h) { // pc
                Editor.Camera.orthoLeft = -this._rangeHalf * (w / h);
                Editor.Camera.orthoRight = this._rangeHalf * (w / h);
                Editor.Camera.orthoTop = this._rangeHalf;
                Editor.Camera.orthoBottom = -this._rangeHalf;
            } else { // mobile
                Editor.Camera.orthoLeft = -this._rangeHalf;
                Editor.Camera.orthoRight = this._rangeHalf;
                Editor.Camera.orthoTop = this._rangeHalf * (h / w);
                Editor.Camera.orthoBottom = -this._rangeHalf * (h / w);
            }
            //}
        }

        Editor.Camera.radius = 30;//50;
        Editor.Camera.target.set(0, 0, 0);
    }

    private checkIDName(id: string, name: Nullable<string>): boolean {
        var skyColor = localStorage.getItem(`${id}${Editor.TypeExt}.SkyColor`);
        var groundColor = localStorage.getItem(`${id}${Editor.TypeExt}.GroundColor`);
        if (id == null)
            return false;

        this._id = id;
        // 没有生产图纸
        if (name == null || name == "") {
            this.ResetCamera();
            //this.ResetScene();
            if (skyColor)
                this.SkyColor = skyColor;
            if (groundColor)
                this.GroundColor = groundColor;
            return true;
        }

        var strs = name.split('_');
        var index = 0;
        // TODO 2021/03/04 服务器不保证id和名字里id相同
        if (id != strs[index++]) {
            //return false;
            console.error(id + " not same with " + name);
        }

        Editor.Camera.alpha = Tools.ToRadians(+strs[index++]);
        Editor.Camera.beta = Tools.ToRadians(+strs[index++]);
        Editor.Camera.radius = +strs[index++];

        // 有缓存以缓存为主，没有则名字为主
        this.SkyColor = skyColor ? skyColor : "#" + strs[index++];
        this.GroundColor = groundColor ? groundColor : "#" + strs[index++];

        return true;
    }

    // (UI界面调用)当存在本地缓存时打开图纸编辑
    public LoadFromCache(id: string, name: Nullable<string> , data: EditorData, models?: Map<string, string>): boolean {
        //console.error("****LoadFromCache****");

        this.clearAll();

        if(!this.checkIDName(id, name))
            return false;

        this._previewFlag = false;

        if (id != data.ID) {
            return false;
        }

        // 目前装载参考模型3D测不缓存
        if (models) {
            for (var i = 0; i < data.Refs.length; i++) {
                var ref = data.Refs[i];
                var modelData = localStorage.getItem(`Model.${ref.ID}${Editor.TypeExt}`);
                if (modelData)
                    models.set(ref.ID, modelData);
            }

            if (data.Refs.some(ref => {
                return (models.get(ref.ID) == null);
            })) {
                // 理论上不会失败
                return false;
            }
        }

        var obj = new EditorData(id, data.Range, data.Blocks, data.Refs);
        this.loadEditorData(obj, models);

        Editor.Camera.useAutoRotationBehavior = false;
        this.checkGameObject();
        this.calBlockNum();

        return true;
    }

    // (UI界面调用)打开图纸编辑
    public Load(id: string, name: Nullable<string>, data?: string /* ref EditorData */, models?: Map<string, string>) :boolean{
        //console.error("****Load****");
        
        this.clearAll();

        if(!this.checkIDName(id, name))
            return false;

        this._previewFlag = false;

        // 有传入数据(则是修改)
        if (data) {
            var obj = new EditorData(id, this.Range);
            obj.Deserialize(data);

            this.loadEditorData(obj, models);
        } /*else {
            this._id = id;
        }*/

        Editor.Camera.useAutoRotationBehavior = false;
        this.checkGameObject();
        this.calBlockNum();

        return true;
    }

    // (UI界面调用)打开图纸预览
    public Preview(id: string, name: string, data: string /* ref EditorData */): boolean {
        this._previewFlag = true;

        this.clearAll();

        if(!this.checkIDName(id, name))
            return false;

        // TODO
        var obj = new EditorData(id, this.Range);
        obj.Deserialize(data);

        this.loadEditorData(obj);

        this.UnSelectAll();

        // 自动旋转
        Editor.Camera.useAutoRotationBehavior = true;
        //this.checkGameObject();

        return true;
    }

    private saveCached() {
        if (this._id != "") {
            var data = this.getEditorData();
            var str = data.Serialize(this.Range, this.Range);

            try {
                localStorage.setItem(`${this._id}${Editor.TypeExt}`, str);

                // TODO 暂不存储操作历史
                //str = JSON.stringify({ history: this._historyList, cursor: this._executeCursor });
                //localStorage.setItem(`${this._id}${Editor.TypeExt}.history`, str);

                console.log("save cache");
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    // (功能调用)图纸是否存在缓存
    public GetCached(id: string): EditorData | null {
        //console.error("****GetCached****");
        var data = localStorage.getItem(`${id}${Editor.TypeExt}`);
        if (data == null)
            return null;

        // TODO
        var editorData = new EditorData(id, this.Range);
        editorData.Deserialize(data);
        return editorData;

        //var obj = JSON.parse(data) as EditorData;
        //return obj;
    }

    // 导出图纸区块链保存信息(取得实际占用格子的最小范围)
    public ExportChainEditorData(): { coordinates: ArrayBuffer, cites: Array<ArrayBuffer> } {
        var blocks: Array<Vector4> = [];

        GameBlock.LiveInstances().forEach(block => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        GameModel.LiveInstances().forEach(model => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        var { platRange, platHeight } = EditorData.FindRange(blocks);
        var data = this.getEditorData();

        var buffer = data.SerializeToBuffer(platRange, platHeight);
        return buffer;
    }

    // 导出图纸信息
    public ExportEditorData(): string {
        var blocks: Array<Vector4> = [];

        GameBlock.LiveInstances().forEach(block => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        GameModel.LiveInstances().forEach(model => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        var { platRange, platHeight } = EditorData.FindRange(blocks);
        var data = this.getEditorData();
        var str = data.Serialize(platRange, platHeight);

        return str;
    }

    // 导出全部格子信息
    public ExportAllBlockData(): string {
        if (this._error != EditorError.Ok) {
            console.log("有错误，清除错误后导出");
            return "<error>";
        }

        if (GameBlock.LiveInstances().length == 0
            && GameModel.LiveInstances().length == 0) {
            console.log("没有任何格子或引用");
            return "<none>";
        }

        var blocks: Array<Vector4> = [];

        GameBlock.LiveInstances().forEach(block => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        GameModel.LiveInstances().forEach(model => {
            model.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        var str = EditorData.SerializeData(blocks);
        //console.log(str);

        return str;
    }

    // 导出选中的格子
    public ExportSelectedBlockData(): string {
        if (this._error != EditorError.Ok) {
            console.log("有错误，清除错误后导出");
            return "<error>";
        }

        if (this._selectedList.size == 0) {
            console.log("没有选择任何格子");
            return "<none>";
        }

        var blocks: Array<Vector4> = [];

        this._selectedList.forEach((item) => {
            item.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        var str = EditorData.SerializeData(blocks);
        //console.log(str);

        return str;
    }

    private loadEditorData(data: EditorData, models?: Map<string, string>) {
        this._id = data.ID;

        // 加载块
        data.Blocks.forEach(block => {
            new GameBlock(new Vector3(block.x, block.y, block.z), block.w, true, true, false);
        });

        // 有传入引用模组则加载模组
        if (models) {
            data.Refs.forEach(ref => {
                // ref.ID
                var refData = models.get(ref.ID);

                if (refData) {
                    this.addModel(ref.ID, refData, ref.Position, ref.Rotation);
                }
            });
        }

        this.checkGameObject();
        this.calBlockNum();
    }

    // 在pos位置能否放下model
    private canPut(pos: Vector3, data: ModelData): boolean {
        //console.log(pos.x + " " + pos.y + " " + pos.z);

        // 是否碰撞到其他元素
        var flag = data.Blocks.some((block) => {
            //console.log(block.x + " " + block.y + " " + block.z);
            var absolutePosition = pos.clone().addInPlaceFromFloats(block.x, block.y, block.z);
            var index = this.cubeIndex(absolutePosition.x, absolutePosition.y, absolutePosition.z);
            if (index < 0 || index >= this._blocks.length)
                return false;

            return (this._blocks[index].size > 0);
        });

        // var index = this.cubeIndex(pos.x, pos.y, pos.z);
        // if (this._blocks[index].size == 0) {
        //     return true;
        // }
        if (flag)
            return false;
        else
            return true;
    }

    //private findPosition(data: ModelData): Nullable<Vector3> {
    private findPosition(data: ModelData): Vector3 {
        var tempPos = new Vector3(0, 0, 0);

        for (var index = 0; index < this._rangeHalf; index++) {
            if (index == 0) {
                if (this.canPut(tempPos, data))
                    return tempPos;

                continue;
            }

            // 上中心点
            // 上左
            tempPos.set(0, 0, 0 + index);
            if (this.canPut(tempPos, data))
                return tempPos;
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(-1, 0, 0);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 上右
            tempPos.set(0, 0, 0 + index);
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(1, 0, 0);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 右中心点
            // 右上
            tempPos.set(0 + index, 0, 0);
            if (this.canPut(tempPos, data))
                return tempPos;
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(0, 0, 1);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 右下
            tempPos.set(0 + index, 0, 0);
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(0, 0, -1);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 下中心点
            // 下左
            tempPos.set(0, 0, 0 - index);
            if (this.canPut(tempPos, data))
                return tempPos;
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(-1, 0, 0);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 下右
            tempPos.set(0, 0, 0 - index);
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(1, 0, 0);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 左中心点
            // 左上
            tempPos.set(0 - index, 0, 0);
            if (this.canPut(tempPos, data))
                return tempPos;
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(0, 0, 1);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // 左下
            tempPos.set(0 - index, 0, 0);
            for (var i = 0; i < index; i++) {
                tempPos.addInPlaceFromFloats(0, 0, -1);
                if (this.canPut(tempPos, data))
                    return tempPos;
            }

            // index --> cubeIndex --> position
        }

        //return null;
        return Vector3.ZeroReadOnly;
    }

    private addModel(id: string, data: string, position: Nullable<Vector3> = null, rotation: Vector3 = Vector3.ZeroReadOnly): GameModel {
        // cache model data
        try {
            localStorage.setItem(`Model.${id}${Editor.TypeExt}`, data);
        }
        catch (e) {
            console.error(e);
        }

        var modelData = new ModelData(id);

        var size = modelData.Deserialize(data);
        console.log("model:" + id + " size:" + size.width + "," + size.depth + "," + size.height);

        // TODO 模型的size --> Range
        // 如果大于模型延伸
        if (position == null) {
            // 找到合适的位置
            position = this.findPosition(modelData);
        }

        // 没有合适的位置
        //if(position == null)
        //    return false;

        //this.Range = size.depth
        var gameModel = new GameModel(position, modelData, rotation, true, true);

        return gameModel;
    }

    // 加载时清除之前数据
    private clearAll() {
        var blocks = GameBlock.LiveInstances();
        for (var i = 0; i < blocks.length; i++) {
            //console.log(i + " clear block");
            blocks[i].Clear();
        }

        var models = GameModel.LiveInstances();
        for (var i = 0; i < models.length; i++) {
            //console.log(i + " clear model");
            models[i].Clear();
        }

        // 初始一些状态
        this._id = "";
        this._selectedList.clear();

        // init
        this._historyList = [];
        this._executeCursor = 0;
    }

    // 取得当前编辑的数据
    private getEditorData(): EditorData {
        var blocks: Array<Vector4> = [];
        var refs: Array<RefModelData> = [];

        GameBlock.LiveInstances().forEach(block => {
            block.ForEach((mesh: Mesh, x: number, y: number, z: number, c: number) => {
                blocks.push(new Vector4(x, y, z, c))
            });
        });

        GameModel.LiveInstances().forEach(model => {
            var rotate = model.Mesh.rotationQuaternion!.toEulerAngles();
            var x = Math.round(Tools.ToDegrees(rotate.x));
            var y = Math.round(Tools.ToDegrees(rotate.y));
            var z = Math.round(Tools.ToDegrees(rotate.z));
            //console.log(x + "," + y + "," + z);
            refs.push(new RefModelData(model.Data.ID, model.Mesh.position, new Vector3(x, y, z)));
        });

        var data = new EditorData(this._id, this._range, blocks, refs);
        return data;
    }

    // 保存图纸时及上链时保存获得数据
    public GetData(flag: boolean = false): Nullable<EditorData> {
        //console.error("****GetData****");

        if (this._id === "") {
            console.log("必须先新建图纸或者编辑图纸");
            return null;
        }

        if (this._error != EditorError.Ok) {
            console.log("有错误，清除错误后导出");
            return null;
        }

        // 如果一个块都没有
        // if (this._blockNum == 0) {
        //     console.log("没有任何块信息");
        //     return null;
        // }

        // flag
        // 服务器存储时，是全部打算成格子
        // 链上时是保存引用关系
        // this._range 求一个区间

        var data = this.getEditorData();

        // 保存清除 localStorage
        localStorage.removeItem(`${this._id}${Editor.TypeExt}`);
        console.log("save remove cached data");

        localStorage.removeItem(`${this._id}${Editor.TypeExt}.SkyColor`);
        localStorage.removeItem(`${this._id}${Editor.TypeExt}.GroundColor`);
        
        return data;
    }
}